<template>
  <div class="modal_container">
    <h1 class="ricotta mb-4">
      Сохранить изменения?
    </h1>
    <p class="mb-8 font">
      Вы внесли изменения в видеокамере «{{ title }}»
    </p>
    <div class="mt-2 buttons">
      <r-button
        class="flex-1"
        type="secondary"
        @click.native="$rir.modal.close()"
        title="Не сохранять"
        width="wide"
      />
      <r-button
        class="flex-1"
        @click.native="save"
        title="Да, сохранить"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaveModal',
  props: {
    saveFunc: Function,
    title: String,
    type: String
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    async save() {
      this.saveFunc();
      this.$rir.modal.close()
    }
  }
};
</script>

<style lang="scss" scoped>
.modal_container{
  max-width: 800px;
  margin: 0 auto;
  padding: 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.buttons{
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px ){
    grid-template-columns: 1fr;
  }
}
</style>
