<template>
  <section class="flex flex-column archive_modal">
    <div class="camembert mb-3">
      {{ getName }}
    </div>
    <div class="parmigiano mb-10">
      {{ item.address }}
    </div>
    <div class="flex">
      <div class="flex flex-1 flex-column mr-8">
        <div class="taleggio mb-6">
          Выберите фрагмент
        </div>
        <r-date-picker
          class="mb-6"
          label="Дата"
          click-close
          :is-input="false"
          width="wide"
          v-model.trim="$v.date.$model"
          :error="$v.date.$error && !$v.date.required"
          error-message="Поле обязательно для заполнения"
        />
        <r-time-picker
          label="Время"
          class="mb-6"
          v-model.trim="$v.time.$model"
          :error="$v.time.$error && !$v.time.required"
          error-message="Поле обязательно для заполнения"
          :is-seconds="true"
        />
        <r-input
          label="Продолжительность, мин"
          after-icon="in-progress"
          v-model.trim="$v.duration.$model"
          :error="$v.duration.$error && !$v.duration.required"
          error-message="Поле обязательно для заполнения"
        />
      </div>
      <video-stream :video="item.url || item.stream" />
    </div>
    <r-button
      @click="orderVideo"
      class="mt-8"
      title="Запросить ролик"
      width="wide"
    />
  </section>
</template>

<script>
import Hls from 'hls.js';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import VideoStream from './VideoStream';
import Api from '../api/Api';

export default {
  name: 'ArchiveVideoModal',
  components: { VideoStream },
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({
    timeout: null,
    date: null,
    time: null,
    duration: null
  }),
  validations: {
    date: {
      required
    },
    time: {
      required
    },
    duration: {
      required
    }
  },
  computed: {
    getName() {
      return this.item.name;
    }
  },
  created() {
    console.log(moment.unix(this.item.timestamp).format('DD-MM-YYYY'))
    this.date = moment.unix(this.item.timestamp).format('YYYY-MM-DD');
    this.time = moment.unix(this.item.timestamp).format('HH:mm:ss');
  },
  mounted() {
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    async orderVideo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const hours = new Date(this.date).setHours(this.time.slice(0, 2));
        const timestamp = new Date(hours).setMinutes(this.time.slice(3));

        await new Api().getArchiveVideo({
          ch: this.item.id,
          container: 'master.m3u8',
          startTime: timestamp
        }).then(res => {
          if (!res.error) {
            const r = this.$router.resolve({
              name: 'index',
              query: { tab: 'video-orders' }
            });
            window.location.assign(r.href);
          }
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.archive_modal{
  max-width: 800px;
  margin: auto;
}
::v-deep .video_container {
  min-height: 0;
  min-width: 0;
  height: 270px;
  width: 480px;
}
</style>
