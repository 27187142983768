<template>
  <div id="app">
    <router-view />
    <r-notification />
    <r-modal
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
export default {
  name: 'VideoControlAdminApp',
  async beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;

  },
  async created() {
    await this.$store.dispatch('getUserAccesses', window.token).then(() => {
      this.CheckUser(this.$store.getters.userAccesses);
    });
    await this.$store.dispatch('getUserInfo');
  },
  methods: {
    CheckUser(user) {
      if (user.status <= 0) {
        // не залогинен
        // window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
        console.log('не авторизован');
      } else {
        // залогинен
        if (!user.access.role === 'ANONYMOUS') {
          // отправить на главную/ обычный юзер
          window.location.href = '/';
          console.log('отправить на главную, обычный юзер или не залогинненый');
        }
      }
    }
  }
};
</script>
<style lang="scss">
.flex-column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
textarea:focus{
  background: transparent;
}
</style>
