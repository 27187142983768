<template>
  <div>
    <loader v-if="isLoading" />
    <template v-else>
      <admin-header
        :title="formData.name || ''"
        class="mb-8"
        :to="{ name: 'admin', query: { tab: 'persons' } }"
      />
      <div class="flex align-end">
        <div class="circle_image mr-4">
          <img
            :src="`data:image/jpeg;base64,${formData.photo.img}`"
            alt=""
          >
        </div>
        <div class="flex align-center opacity-72 feta mr-6">
          <r-icon
            class="mr-2"
            icon="info"
            fill="titanic"
          />
          {{ getFormattedAge(formData.birth_date) }}, {{ formData.gender === 1 ? 'мужчина' : 'женщина' }}
        </div>
        <div class="flex align-center opacity-72 feta mr-6">
          <r-icon
            class="mr-2"
            icon="video-control"
            fill="titanic"
          />
          Найден на видео 32 раза
        </div>
        <div class="flex align-center opacity-72 feta mr-6">
          <r-icon
            class="mr-2"
            icon="selected"
            fill="titanic"
          />
          c 15.08.2021 10:08:12
        </div>
      </div>

      <r-tabs
        class="mt-7 mb-6"
        :items="tabs"
        v-model="activeTabId"
      />
      <person-details-tab v-if="activeTabId.id === 'cameras'" />
      <person
        v-if="activeTabId.id === 'settings'"
        :header="false"
        :data="formData"
      />
    </template>
  </div>
</template>

<script>
import AdminHeader from '../components/AdminHeader';
import ModalViewImage from '../components/ModalViewImage';
import DeleteModal from '../components/DeleteModal';
import ArchiveVideoModal from '../components/ArchiveVideoModal';
import Api from '../api/Api';
import Person from './Person';
import { formattedDate, getAge } from '../helpers/utils';
import PersonDetailsTab from '../components/Persons/PersonDetailsTab';
import Loader from '../components/Loader.vue';
import moment from "moment";
export default {
  name: 'PersonDetails',
  components: {
    Loader,
    Person,
    AdminHeader,
    PersonDetailsTab
  },
  data() {
    return {
      activeTabId: {
        id: 'cameras',
        title: 'Камеры'
      },
      tabs: [
        {
          id: 'cameras',
          title: 'Камеры'
        },
        {
          id: 'settings',
          title: 'Настройки профиля'
        }
      ],
      isSave: true,
      flyButton: '',
      isLoading: false,
      initialData: {},
      formData: {
        name: null,
        gender: null,
        birth_date: null,
        comment: '',
        contact_info: '',
        photo: {
          img: null
        }
      },
      findList: []
    };
  },
  computed: {
    isEditPage() {
      return !!this.$route.params.id;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  mounted() {},
  async activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && await this.setData(this.$route.params.id);
  },

  deactivated() {
    this.isShowMap = false;
    this.resetData();
  },
  methods: {
    getFormattedAge(birthdate) {
      return getAge(birthdate);
    },
    resetData() {
      this.initialData = JSON.parse(JSON.stringify(this.formData));
      for (const prop in this.formData) {
        this.formData[prop] = this.initialData[prop];
      }
    },
    async setData(id) {
      this.isLoading = true;
      if (id) {
        await new Api().getPersonById(this.$route.params.id).then(res => {
          this.formData = { ...this.formData, ...res };
          this.isLoading = false;
        });
      }
    },
    async openArchiveVideoModal() {
      this.$rir.modal.open(ArchiveVideoModal, {
        item: {}
      });
    },
    async onDelete() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить пользователя?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: this.delete
      });
    },
    choiceAll() {
      if (this.formData.allChoice) {
        this.formData.opportunities = this.defaultOpportunities;
      } else {
        this.formData.opportunities = [];
      }
    },
    changeGroup(item) {
      this.selectedGroup = item;
    },
    addGroup() {
      this.formData.groups.push(this.selectedGroup);
      this.selectedGroup = null;
      this.isChange('groups');
    },
    onViewImage() {
      this.$rir.modal.open(ModalViewImage, {
        file: this.formData.imgUrl
      });
    },
    deleteFile() {
      this.formData.imgUrl = null;
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$rir.modal.open(ModalCrop, {
        image: this.loadedImg,
        valName: target === 'staff' ? 'contactImg' : 'imgUrl',
        portrait: target === 'staff'
      });
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        console.log('submit');
        /*
        *   let r = this.$router.resolve({
            name: 'index',
            query: {
              tab: 'statements'
            }
          });
          window.location.assign(r.href) */
      }
    },
    onChangeOrganization(val) {
      console.log(val);
      this.isChange('firmId');
    },
    stringToCoords(string) {
      return JSON.parse(`[${string}]`);
    },
    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.formData.coordinates = coords;
      }
    },
    onDragMarker(e) {
      this.formData.coordinates = e.get('target').geometry._bounds[0];
    },
    onChangeCondition(val) {
      this.formData.condition = val;
      this.isChange('condition');
    },
    isChange(field) {
      /* if (this.initialData[field] === this.formData[field]) {
         this.isSave = true;
       } else {
         this.isSave = false;
       } */
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}
.circle_image{
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 50%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

</style>
