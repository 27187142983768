import axios from 'axios';

export const getNumberOfDays = endDate => {
  const date1 = new Date();
  const date2 = new Date(endDate);

  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};
export const getAge = birthDate => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dob = new Date(birthDate);
  const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
  let age;
  age = today.getFullYear() - dob.getFullYear();
  if (today < dobnow) {
    age -= 1;
  }
  const yo = y => (/\d*1\d$/.test(y) || /[05-9]$/.test(y) ? 'лет' : (/1$/.test(y) ? 'год' : 'года'));
  return `${age} ${yo(age)}`;
};

export const getEmptyField = (details, arr = []) => {
  for (const elem in details) {
    if (!details[elem]) arr.push(elem);
  }
  return arr;
};
export const mappedTableList = arr => [...arr].map(item => ({
  id: item.id,
  organizationId: item.id,
  status: '0',
  isAlignment: item.alignment === 'checked',
  details: {
    title: item.details?.title,
    // inn: item.details.inn,
    phone: item.details?.confidant?.phone || '',
    email: item.details?.confidant?.email || '',
    ceoName: item.details?.confidant?.fio,
    address: item.details?.address
  },
  dragIndex: item.details?.dragIndex,
  errored_fields: getEmptyField({
    ceoName: item.details?.confidant?.fio
  }) // названия пустых полей
}));
export const getDayAddition = num => {
  const preLastDigit = num % 100 / 10;
  if (preLastDigit === 1) {
    return 'дней';
  }
  switch (num % 10) {
    case 1:
      return 'день';
    case 2:
    case 3:
    case 4:
      return 'дня';
    default:
      return 'дней';
  }
};
export const formattedFiles = files => {
  const formattedFiles = [];

  files.forEach(el => {
    if (el?.files?.length) {
      const fileList = el.files.map(el => ({
        contentType: el.type,
        filename: el.name, // .split('.')[0]
        icon: false,
        url: el.url
      }));
      formattedFiles.push(...fileList);
    } else {
      formattedFiles.push(el);
    }
  });
  return formattedFiles;
};
export const wordMatch = (left, right) => {
  // 0 - полное совпадение
  // 1 - нет совпадений
  left = left.toLowerCase();
  right = right.toLowerCase();

  if (left.includes(right)) {
    return 0;
  }
  left = `\b\b${left.toLowerCase()}\f\f`;
  right = `\b\b${right.toLowerCase()}\f\f`;

  let dist = -4;

  for (let i = 0; i < left.length - 2; ++i) {
    if (!right.includes(left.slice(i, i + 3))) ++dist;
  }

  for (let i = 0; i < right.length - 2; ++i) {
    if (!left.includes(right.slice(i, i + 3))) ++dist;
  }

  return Math.max(0, dist) / (left.length + right.length - 8);
};
export const formattedDate = (date, format = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}) => {
  if (!date) return;
  return new Date(date).toLocaleString('Ru-ru', format);
};
export const getCameraMarker = angle => ({
  imageHref: `data:image/svg+xml;charset=UTF-8,%3csvg width='90' height='90' viewBox='0 0 68 57' fill='none' xmlns='http://www.w3.org/2000/svg' transform='rotate(${angle} 0 0)'%3e%3cpath d='M34 46L68 12.1366C41.7103 -3.90553 26.8506 -4.18495 0 12.1366L34 46Z' fill='url(%23paint0_linear_3_60897)'/%3e%3ccircle cx='34' cy='45.0005' r='9' fill='white' stroke='%233D75E4' stroke-width='6'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_3_60897' x1='34' y1='0' x2='34' y2='46' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%233D75E4' stop-opacity='0'/%3e%3cstop offset='1' stop-color='%233D75E4'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e`,
  layout: 'default#image',
  imageSize: [90, 90]
});
export const validateCarPlate = plate => {
  const re = new RegExp('([ABEKMHOPCTYX]){1}\\d{3}([ABEKMHOPCTYX]){2}\\d{1,3}');
  return re.test(plate);
};

export const declination = (number, txt, cases = [2, 0, 1, 1, 1, 2]) => txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];

export const getImage = async id => {
  const token = window.token ? window.token : await window?.getToken;
  await axios.get(`/cctv/rest/cameras/${id}/image`, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => {
      const blob = new Blob([response.data]);
      return URL.createObjectURL(blob);
    });
};
