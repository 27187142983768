import Api from '@/api/Api';

const api = new Api();
export default {
  state: () => ({
    cameras: [],
    camerasLoading: true
  }),
  mutations: {
    setCameras(state, res) {
      state.cameras = res;
    },
    setLoading(state, bool) {
      state.camerasLoading = bool;
    }
  },
  actions: {
    async getCameras(ctx, camType) {
      ctx.commit('setLoading', true);
      const res = await api.getCameras(camType);
      ctx.commit('setCameras', res.all);
      ctx.commit('setLoading', false);
      return res;
    }

  },
  getters: {
    getAllCameras(state) {
      return state.cameras;
    }
  }
};
