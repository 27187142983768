<template>
  <div>
    <loader v-if="loading" />
    <img
      v-else
      alt=""
      :src="src"
      ref="image"
    >
  </div>
</template>

<script>
import axios from 'axios';
import Loader from './Loader';

export default {
  name: 'LoadImage',
  components: {
    Loader
  },
  props: {
    src: {
      type: String,
      required: true
    },
    status: {
      type: String
    }
  },
  data() {
    return {
      loading: true,
      interval: null,
      image: null
    };
  },
  mounted() {
    this.setImage();
  },
  methods: {
    async reloadImg() {
      this.interval = setTimeout(async () => {
        await axios.get(this.src).then(res => {
          if (res.data.status === 'REQUESTED') {
            this.reloadImg();
            this.$refs.image.src = `/trassir/rest/scr/${res.data.id}/thumb`;
          } else {
            this.$refs.image.src = res.url;
          }
        }).finally(() => {
          this.loadImage();
        });
      }, 30000);
    },
    loadImage() {
      const img = new Image();
      img.src = this.src;

      img.onload = () => {
        this.loading = false;
        this.$refs.image.src = img.src;
        if (this.interval) {
          clearTimeout(this.interval);
        }
      };
    },
    setImage() {
      console.log('setImage', this.status);
      if (this.status === 'REQUESTED') {
         this.reloadImg();
      } else {
        this.loadImage();
      }
    }
  }
};
</script>

<style scoped>

img {
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
}
</style>
