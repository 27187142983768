<template>
  <div>
    <div class="flex align-center">
      <r-date-picker
        label="Начало отслеживания"
        class="flex-1 mr-6"
        v-model="selectedDate"
      />
      <r-input
        class="flex-1"
        label="Поиск"
        v-model="search"
      >
        <template #after>
          <r-icon
            icon="search"
            fill="titanic"
            class="anie"
          />
        </template>
      </r-input>
    </div>
    <div class="flex justify-end parmigiano color-titanic opacity-48  mt-6 mb-2">
      {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
    </div>
    <vue-table
      :list="list"
      :headings="headings"
      :columns-grid="tableGridColumns"
      row-link="/person-details/"
      class="rows_space-between"
      :circle-screenshot="true"
      :lazy-load="true"
    />
  </div>
</template>

<script>
import VueTable from '../Table';
import Api from '../../api/Api';
import { getAge, formattedDate } from '../../helpers/utils';

export default {
  name: 'SubTabMonitored',
  components: { VueTable },
  data() {
    return {
      selectedDate: null,
      searchText: '',
      timeoutId: null,
      headings: [
        {
          title: 'Фото эталон',
          field: 'screenshot'
        },
        {
          title: 'ФИО',
          field: 'fio'
        },
        {
          title: 'Найден на видео',
          field: 'hit'
        },
        {
          title: 'Начало отслеживания',
          field: 'date'
        },
        {
          title: 'Отслеживание',
          field: 'monitored'
        }
      ],
      tableGridColumns: ['100px', '350px', '130px', '200px', '170px'],
      list: []
    };
  },
  computed: {
    filteredObjects() {
      let { list } = this;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => el?.columns.name?.toLowerCase().indexOf(this.searchText) > -1
            || el?.details.projectName?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  async created() {
    await new Api().wantedVideo({
      wantedVideoOption_2: 1,
      from: '',
      to: ''
    }).then(res => {
      this.list = res?.all?.map(el => ({
        id: el.person.ext_person_id,
        trackingId: el.id,
        columns: {
          screenshot: '',
          name: el.person.name,
          hit: {
            title: el.hit,
            subtitle: el.hitLastReport
          },
          date: formattedDate(el.ackReport, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }),
          deleteMonitored: true
        }
      })) || [];
    });
  }
};
</script>

<style scoped>

</style>
