<template>
  <div>
    <!-- <div class="flex align-center">
      <r-date-picker
        label="Начало отслеживания"
        class="flex-1 mr-6"
        v-model="selectedDate"
      />
      <r-select
        class="mr-4 flex-1"
        :items="sortCarPlateType"
        label="Госномера"
        v-model="selectedCarPlateType"
      />
      <r-input
        class="flex-1"
        label="Поиск"
        v-model="search"
      >
        <template #after>
          <r-icon
            icon="search"
            fill="titanic"
            class="anie"
          />
        </template>
      </r-input>
      <r-button-simple
        class="ml-4"
        icon="icon-view"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>-->
    <div class="flex justify-end parmigiano color-titanic opacity-48  mt-6 mb-2">
      {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <vue-table
        :list="filteredObjects"
        :headings="headings"
        :columns-grid="tableGridColumns"
        class="rows_space-between"
      />
    </template>
  </div>
</template>

<script>
import VueTable from './Table';
import Api from '../api/Api';
import { formattedDate } from '../helpers/utils';
import Loader from './Loader';

export default {
  name: 'TabVideoOrder',
  components: { VueTable, Loader },
  data() {
    return {
      selectedDate: null,
      searchText: '',
      headings: [
        {
          title: 'Наименование камеры',
          field: 'name'
        },
        {
          title: 'Начало отслеживания',
          field: 'date'
        },
        {
          title: 'Длительность, c',
          field: 'duration'
        },
        {
          title: 'Статус',
          field: 'cam_status'
        }
      ],
      tableGridColumns: ['450px', '200px', '150px', '100px', '150px'],
      list: [],
      sortCarPlateType: [
        {
          id: 'all',
          title: 'Все'
        },
        {
          id: 'group',
          title: 'Только группы номеров'
        },
        {
          id: 'single',
          title: 'Только номера'
        }
      ],
      selectedCarPlateType: 'all',
      selectedView: 'table',
      isLoading: true,
      videoStatuses: {
        IN_PROGRESS: 'В работе',
        NEW: 'Новый',
        ERROR: 'Ошибка',
        FILE_CREATED: 'Файл создан',
        REQUESTED: 'Запрошен',
        FILE_UPLOADED: 'Файл загружен',
        COMPLETED: 'Выполнено'
      }
    };
  },
  computed: {
    filteredObjects() {
      let { list } = this;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => el?.details.address?.toLowerCase().indexOf(this.searchText) > -1
            || el?.details.projectName?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  async created() {
    await new Api().getArchive().then(res => {
      if (!res.error) {
        this.list = res?.all?.map(el => ({
          id: el.id,
          columns: {
            name: el.channel.name,
            date: {
              title: formattedDate(el.startTs),
              subtitle: formattedDate(el.startTs, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              })
            },
            duration: el.duration,
            video_status: this.videoStatuses[el.status],
            videoDownload: true
          }
        })) || [];
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }
};
</script>

<style scoped lang="scss">
::v-deep .RButtonSimple{
  width: 40px;
  height: 40px;
}
.history {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 24px;

  &__item {
    * {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      * {
        color: #214eb0;
      }
    }
  }
}
</style>
