<template>
  <div class="objects__list">
    <cam-card
      v-for="card in filteredObjects"
      :key="card.id"
      :card="card"
      :selected-sort-cam-type="selectedSortCamType"
    />
  </div>
</template>

<script>
import CamCard from './CamCard';

export default {
  name: 'CamerasObjectList',
  components: {
    CamCard
  },
  props: {
    filteredObjects: {
      type: Array,
      required: true
    },
    selectedSortCamType: {
      type: String,
      default: 'client'
    }
  }
};
</script>

<style scoped lang="scss">
.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}
</style>
