<template>
  <div>
    <div class="flex align-center">
      <r-date-picker
        class="flex-1"
        period
        label="Период"
        v-model="period"
        is-timestamp
      />
      <!--      <r-input-->
      <!--        class="flex-1"-->
      <!--        label="Поиск"-->
      <!--        v-model="search"-->
      <!--      >-->
      <!--        <template #after>-->
      <!--          <r-icon-->
      <!--            icon="search"-->
      <!--            fill="titanic"-->
      <!--            class="anie"-->
      <!--          />-->
      <!--        </template>-->
      <!--      </r-input>-->
    </div>
    <div class="flex justify-end parmigiano color-titanic opacity-48  mt-6 mb-2">
      {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
    </div>
    <vue-table
      :list="filteredObjects"
      :headings="headings"
      :columns-grid="tableGridColumns"
      class="rows_space-between"
    />

    <!-- <template v-if="!search || !period.length === 2">
      <p class="mozzarella mb-6 mt-6 opacity-48">
        История запросов
      </p>
      <div class="history">
        <router-link
          to="#"
          class="history__item"
          v-for="item of 5"
          :key="item"
        >
          <span class="sulguni opacity-72 block">Кудров Валерий Михайлович{{ item }}</span>
          <span class="briscola opacity-48 block">28.11.2020 15:30:02</span>
        </router-link>
      </div>
    </template>-->
  </div>
</template>

<script>
import moment from 'moment';
import VueTable from '../Table';
import Api from '../../api/Api';
import { formattedDate } from '../../helpers/utils';

export default {
  name: 'PersonDetailsTab',
  components: { VueTable },
  data() {
    return {
      period: [
        moment().subtract(1, 'months').unix(),
        moment().unix()
      ],
      searchText: '',
      headings: [
        {
          title: 'Скриншот',
          field: 'screenshot'
        },
        {
          title: 'ФИО персоны',
          field: 'fio'
        },
        {
          title: 'Совпадение',
          field: 'match'
        },
        {
          title: 'Дата',
          field: 'date'
        }
      ],
      tableGridColumns: ['103px', '376px', '90px', '131px', '170px'],
      list: []
    };
  },
  computed: {
    filteredObjects() {
      let { list } = this;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => el?.details.address?.toLowerCase().indexOf(this.searchText) > -1
            || el?.details.projectName?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  methods: {
    async getData() {
      // список попаданий
      await new Api().getPersonMonitoredById({
        id: this.$route.params.id,
        from: this.period[0],
        to: this.period[1]
      }).then(res => {
        this.findList = res?.map(el => ({
          id: el.id,
          columns: {
            screenshot: `/trassir/rest/scr/${el.id}/thumb`,
            fio: el.name,
            match: `${el.score / 100}%`,
            date: {
              title: moment(el.from).format('DD.MM.YYYY'),
              subtitle: new Date(el.from).getTime()
            },
            videoView: true
          }
        }));
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.history {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 24px;

  &__item {
    * {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      * {
        color: #214eb0;
      }
    }
  }
}
</style>
