import axios from 'axios';
import rir from '../plugins/RirLib';

export default class ApiService {
  token = null;
  _axios = null;
  constructor() {
    // this.token = Vue.prototype.$keycloak.kk.token;
    this._axios = axios.create({
      baseURL: process.env.VUE_APP_API_SERVER,
      headers: {
        'Content-Type': 'application/json',
        common: {
          Authorization: `Bearer ${window.token}`
        }
      }
    });

    // if (window.token || !!window?.getToken) {
    //   this._axios.interceptors.request.use(async config => {
    //     const token = window.token ? window.token : await window?.getToken();
    //     config.headers.Authorization = `Bearer ${token || window.token}`;
    //     return config;
    //   });
    // }
    const sendMessage = message => {
      rir.framework.notification.send({
        title: message,
        seconds: 5,
        hiddenClose: false,
        iconOptions: {
          icon: 'warning',
          fill: 'fargo'
        }
      });
    };
    this._axios.interceptors.response.use(response => { // For status code with 2xx
      if (response?.data?.error) {
        sendMessage(response?.data?.error || 'Ошибка!');
      }
      return response;
    }, async error => { // Status code outside the range of 2xx
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newToken = await window?.getToken();
          this._axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          return this._axios(originalRequest);
        } catch (err) {
          sendMessage('Ошибка обновления токена:', err);
        }
      } else {
        sendMessage(error);
      }

      return Promise.reject(error);
    });
  }
}
