<template>
  <router-link
    :to="{ path: `cameras-details/${marker.id}`, query: { trassir: marker.isTrassir}}"
    class="r-map-balloon pointer flex flex-column"
  >
    <div class="r-map-balloon__img">
      <img
        :src="marker.photo"
        :alt="marker.description"
        v-if="!!marker.photo"
      >
      <r-icon
        v-else
        icon="video-control"
        fill="alien"
        size="32"
      />
    </div>
    <div class="pa-4">
      <p class="roquefort mb-1">
        {{ marker.description }}
      </p>
      <p class="сaprino opacity-72">
        {{ marker.address }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;

  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4EDFB;
    overflow: hidden;
  }
  &__info {
    padding: 12px 16px 0;
  }
}

</style>
