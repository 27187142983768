import ApiService from './ApiService';

let proxy = '';
if (process.env.NODE_ENV === 'development') {
  proxy = '/proxy';
}
export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post(`${proxy}/mob_ajax.php?action=geosearch`, {
      geocodeEntireRegion: 1,
      ...JSON.parse(payload)
    });
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/CCTV');
    return data;
  }
  async getUserAccessesArchive() {
    const { data } = await this._axios.get('/auth/rest/access/CAM_ARCHIVE');
    return data;
  }
  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  // cameras
  async getCameras(camType, name = '') {
    if (camType === 'trassir') {
      const { data } = await this._axios.get('/trassir/rest/channel?all=true');
      return data;
    }
    const { data } = await this._axios.post(`${proxy}/ajax.php?action=getCameras`, {
      currentMainMenuNumber: 2,
      camerasOption_1: String(camType) === 'client', // true - показывать клиентские
      camerasOption_2: 0, // true - показывать трассир
      camerasOption_3: false,
      camerasOption_4: false,
      zakazVideoOption_2: false,
      camerasOptionSub_1: true,
      camerasOptionSub_2: false,
      camerasOptionSub_3: false,
      camerasOptionSub_4: false,
      camerasOptionSub_5: true,
      isRostelekomExists: false,
      editing: false
    });
    return data.all;
  }

  async getCamerasById(id, isTrassir = 0) {
    // const { data } = await this._axios.post(`${proxy}/ajax.php?action=getCameras`, {
    //   id,
    //   camerasOption_1: Number(!isTrassir),
    //   camerasOption_2: isTrassir,
    //   camerasOptionSub_5: true
    // });
    // return data.all[0];
    const { data } = await this._axios.get(`/trassir/rest/channel/${id}`);
    return data;
  }

  async getUrlImage(id) {
    const { data } = await this._axios.get(`/trassir/rest/cameras/${id}/thumb`);
    return data;
  }

  async getObjectsInFrameCameras() {
    const { data } = await this._axios.get('/trassir/rest/channel?objectsInFrame=true');
    return data;
  }

  async operateCamera({ id, payload }) {
    if (id === -1) {
      const { data } = await this._axios.post('/trassir/rest/cameras', payload);
      return data;
    }
    const { data } = await this._axios.put(`/trassir/rest/cameras/${id}`, payload);
    return data;

    // const { data } = await this._axios.post(`${proxy}/ajax.php?action=operateCamera`, payload);
    // return data;
  }

  // stream
  async getFromUrl(url) {
    const { data } = await this._axios.get(url);
    return data;
  }

  async getTrassirStream({ id, start }) {
    const { data } = await this._axios.get(`${proxy}/trassir/rest/stream/${id}/master.m3u8`, {
      params: {
        start
      }
    });
    return data;
  }

  async zakazVideo(payload) {
    const { data } = await this._axios.post(`${proxy}/ajax.php?action=zakazVideo`, payload);
    return data;
  }

  async downloadVideo(orderId) {
    const { data } = await this._axios.get(`${proxy}/ajax.php?action=zakazVideo&&orderId=${orderId}`);
    return data;
  }

  async getSensorCam(url, params) {
    const { data } = await this._axios.get(url, {
      params
    });
    return data;
  }

  async getScreenshotSensorCam(url, params) {
    return await this._axios.get(url, {
      params
    });
  }

  async getTrafficChart(payload) {
    const { data } = await this._axios.get('/trassir/rest/plate/graph/total/by/hour', {
      params: payload
    });
    return data;
  }

  async getCamerasTypes() {
    const { data } = await this._axios.get('/trassir/rest/cameras/types');
    return data;
  }

  // image

  async deleteCameraImage(id) {
    const { data } = await this._axios.delete(`/trassir/rest/cameras/${id}/image`);
    return data;
  }

  async updateCameraImage(id, image) {
    const fd = new FormData();
    fd.append('image', image, image.name);

    const { data } = await this._axios.put(`/trassir/rest/cameras/${id}/image`, fd);
    return data;
  }

  // persons

  async getPersons(params) {
    const { data } = await this._axios.get('/trassir/rest/person');
    return data;
  }

  async getMonitoredPersons(params) {
    const { data } = await this._axios.get('/trassir/rest/monitoring/person?onlyActive=true');
    return data;
  }

  async updatePerson(person) {
    console.log(person);
    const { data } = await this._axios.put('/trassir/rest/person', person);
    return data;
  }

  async getPersonById(id) {
    const { data } = await this._axios.get(`/trassir/rest/person/${id}/profile`);
    return data;
  }

  async getPersonMonitoredById({ id, from, to }) {
    const { data } = await this._axios.get(`/trassir/rest/person/${id}`, {
      params: {
        from, to
      }
    });
    return data;
  }

  async getPersonPhoto(guid) {
    const { data } = await this._axios.get(`/trassir/rest/person/${guid}/photo`);
    return data;
  }

  async addPerson(payload) {
    const { data } = await this._axios.post('/trassir/rest/person', payload);
    return data;
  }

  // car Plates

  async getCarPlates() {
    const { data } = await this._axios.get('/trassir/rest/monitoring/plate');
    return data;
  }

  async findCarPlate({ plate, from, to }) {
    const { data } = await this._axios.get(`/trassir/rest/plate/${plate}`, {
      params: {
        from, to
      }
    });
    return data;
  }

  // archive

  async getArchive() {
    const { data } = await this._axios.get('/trassir/rest/archive');
    return data;
  }

  async getArchiveVideo({ ch, container, startTime }) {
    const { data } = await this._axios.get('/trassir/rest/archive/stream', {
      params: {
        ch, container, startTime
      }
    });
    return data;
  }

  // monitoring //
  async changeMonitoring(id) {
    const { data } = await this._axios.put(`/trassir/rest/monitoring/${id}`);
    return data;
  }

  async getMonitoringPlateById(id) {
    const { data } = await this._axios.get(`/trassir/rest/monitoring/plate/${id}?all=true`);
    return data;
  }

  async addMonitoringPerson(person) {
    const { data } = await this._axios.post('/trassir/rest/monitoring', {
      person: {
        ...person,
        monitored: true,
        deleted: false
      },
      common: false,
      hit: 0,
      hitLastView: 0,
      hitLastReport: 0,
      hidden: false

    });
    return data;
  }

  async deleteMonitoring(id) {
    const { data } = await this._axios.delete(`/trassir/rest/monitoring/${id}`);
    return data;
  }

  async addMonitoringPlate(plate) {
    const { data } = await this._axios.post('/trassir/rest/monitoring', {
      plate
    });
    return data;
  }
}
