<template>
  <div>
    <div class="flex align-center">
      <r-date-picker
        class="flex-1 mr-6"
        label="Дата"
        v-model="date"
        @change="getSensor"
        :is-timestamp="true"
        :time-millis="false"
      />
      <r-time-picker
        :disabled="date === null"
        class="flex-1 mr-6"
        :is-clear-model="true"
        label="Время c"
        :step-minutes="5"
        v-model="timeFrom"
        @input="getSensor"
      />
      <r-time-picker
        :disabled="date === null"
        class="flex-1"
        :start-time="timeFrom"
        :is-clear-model="true"
        label="Время по"
        :step-minutes="5"
        v-model="timeTo"
        @input="getSensor"
      />
    </div>
    <div class="flex justify-end parmigiano color-titanic opacity-48  mt-6 mb-2">
      {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
    </div>
    <vue-table
      :list="list"
      :headings="headings"
      :columns-grid="tableGridColumns"
      class="rows_space-between"
      :infinite-loading="true"
      :not-link-row="true"
    />
    <infinite-loading
      @infinite="infiniteHandler"
      ref="infinite"
    >
      <div slot="spinner">
        <div class="flex justify-content-center my-3">
          <r-spinner />
        </div>
      </div>
      <div slot="no-more" />
      <div slot="no-results">
        <not-found />
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import infiniteLoading from 'vue-infinite-loading';
import moment from 'moment';
import VueTable from '../Table';
import Api from '../../api/Api';
import { formattedDate } from '../../helpers/utils';
import notFound from '../NotFound';

export default {
  name: 'CamerasDetailsSensorsTab',
  components: { VueTable, infiniteLoading, notFound },
  data() {
    return {
      date: null,
      timeFrom: null,
      timeTo: null,
      selectedSortType: 'ALL',
      sortTypes: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: 'SMOKE_DETECTED',
          title: 'Дым'
        },
        {
          id: 'OBJECT_SIZE_ALARM',
          title: 'Крупногабаритный транспорт'
        },
        {
          id: 'FIRE_DETECTED',
          title: 'Пожар'
        }
      ],
      events: {
        SMOKE_DETECTED: 'Дым',
        OBJECT_SIZE_ALARM: 'Крупногабаритный транспорт',
        FIRE_DETECTED: 'Пожар'
      },
      searchText: '',
      headings: [
        {
          title: 'Скриншот',
          field: 'screenshot'
        },
        {
          title: 'Дата',
          field: 'date'
        }
      ],
      tableGridColumns: ['103px', '120px', '170px'],
      list: [],
      page: 1,
      limit: 50,
      countObjects: 0
    };
  },
  computed: {
    // countObjects() {
    //   return this.list.length;
    // }
  },
  mounted() {
    // this.getSensor();
  },
  methods: {
    async infiniteHandler($state) {
      this.infiniteState = $state;
      const setTimeForDate = (date, hour = 0, minute = 0, second = 0, selectedTime) => {
        let dateTime = new Date(date * 1000).setHours(hour, minute, second);
        if (selectedTime !== null) {
          const a = selectedTime.split(':');
          dateTime = new Date(date * 1000).setHours(a[0], a[1], 0);
        }
        console.log(new Date(dateTime).getTime() / 1000);
        return (new Date(dateTime).getTime() / 1000);
      };
      await new Api().getSensorCam('/trassir/rest/nod/page', {
        ch: this.$route.params.id,
        from: this.date ? setTimeForDate(this.date, 0, 0, 0, this.timeFrom) : null,
        to: this.date ? setTimeForDate(this.date, 23, 59, 59, this.timeTo) : null,
        limit: this.limit,
        skip: (this.page * this.limit) - this.limit
      }).then(res => {
        const objects = res.data.content;
        this.countObjects = res.data.filteredTotal;
        if (objects.length) {
          this.page += 1;
          this.list.push(...objects.map(el => ({
            ...el,
            screenshot: `/trassir/rest/scr/${el.screenshot.id}`,
            columns: {
              screenshot: `/trassir/rest/scr/${el.screenshot.id}/thumb`,
              date: {
                title: moment.unix(el.timestamp).format('DD.MM.YYYY'), // formattedDate(Number(el.timestamp * 1000)),
                subtitle: moment.unix(el.timestamp).format('HH:mm:ss')
              },
             // videoView: true
            }
          })));
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    getSensor() {
      this.page = 1;
      this.list = [];
      this.infiniteState.reset();
    }
  }
};
</script>

<style scoped lang="scss">
.history {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 24px;

  &__item {
    * {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      * {
        color: #214eb0;
      }
    }
  }
}
</style>
