<template>
  <r-popover
    content-class="r-select__content r-popover__radius"
    :disabled="disabled"
    v-model="showSelect"
  >
    <template v-slot:activator="{ on }">
      <r-input
        readonly
        :value="inputValues"
        @click.native="on.click"
        class="r-select"
        :label="label"
        :class="{
          disabled: readonly,
        }"
      >
        <a
          class="r-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
        >
          <r-icon
            :size="20"
            icon="arrow-down"
          />
        </a>
      </r-input>
    </template>
    <r-list overflow>
      <r-list-item>
        <template>
          <slot />
        </template>
      </r-list-item>
      <r-list-item>
        <template>
          <label
            for="checkbox_all"
            class="r-checkbox mb-1"
          >
            <input
              id="checkbox_all"
              type="checkbox"
              @change="choiceAll"
              v-model="allChoice"
            >
            <span
              class="r-checkbox__input mr-1"
              style="flex-shrink: 0"
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                style="bottom: -1px"
                xmlns="http://www.w3.org/2000/svg"
                class="r-checkbox__selected"
              >
                <g filter="url(#filter0_d_9825:3967)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.86988 5.87545C6.13398 6.00796 6.45708 6.06355 6.80247 5.77377L12.928 0.267583C13.5208 -0.408639 14.2504 0.364081 13.9156 0.750518L7.29683 9.63776C6.90165 10.1207 6.2101 10.1208 5.81493 9.63776L2.09861 4.83662C1.76387 4.42137 2.35677 3.93837 2.75217 4.22817L5.86988 5.87545Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_9825:3967"
                    x="0"
                    y="0"
                    width="16"
                    height="14"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      flood-opacity="0"
                      result="BackgroundImageFix"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0745098 0 0 0 0 0.223529 0 0 0 0 0.52549 0 0 0 0.32 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_9825:3967"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_9825:3967"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </span>
            Все
          </label>
        </template>
      </r-list-item>
      <r-list-item
        v-for="checkbox of items"
        :key="checkbox.id"
      >
        <template>
          <label
            :for="'checkbox_' + checkbox.id"
            class="r-checkbox mb-1"
          >
            <input
              :id="'checkbox_' + checkbox.id"
              type="checkbox"
              :value="checkbox"
              v-model="choicest"
              @change="changeCheckbox"
              @click="allChoice = false"
            >
            <span
              class="r-checkbox__input mr-1"
              style="flex-shrink: 0"
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                style="bottom: -1px"
                xmlns="http://www.w3.org/2000/svg"
                class="r-checkbox__selected"
              >
                <g filter="url(#filter0_d_9825:3967)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.86988 5.87545C6.13398 6.00796 6.45708 6.06355 6.80247 5.77377L12.928 0.267583C13.5208 -0.408639 14.2504 0.364081 13.9156 0.750518L7.29683 9.63776C6.90165 10.1207 6.2101 10.1208 5.81493 9.63776L2.09861 4.83662C1.76387 4.42137 2.35677 3.93837 2.75217 4.22817L5.86988 5.87545Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_9825:3967"
                    x="0"
                    y="0"
                    width="16"
                    height="14"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      flood-opacity="0"
                      result="BackgroundImageFix"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0745098 0 0 0 0 0.223529 0 0 0 0 0.52549 0 0 0 0.32 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_9825:3967"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_9825:3967"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </span>
            {{ checkbox.value }}
          </label>
        </template>
      </r-list-item>
    </r-list>
  </r-popover>
</template>

<script>
export default {
  name: 'SelectCheckboxes',
  components: {},
  props: {
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'Выбрать'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showSelect: false,
    item: null,
    choicest: [],
    allChoice: false
  }),
  computed: {
    inputValues() {
      const arr = this.choicest.map(item => item.value);
      return arr.join('; ');
    }
  },
  watch: {},
  created() {
  },
  methods: {
    changeCheckbox() {
      this.$emit('input', this.choicest);
    },
    choiceAll() {
      if (this.allChoice) {
        this.choicest = this.$props.items;
      } else {
        this.choicest = [];
      }
    }
  }
};
</script>
<style lang="scss">
.opacity {
  opacity: 1;
}

.r-input__input input {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.r-list > .r-list-item * {
  opacity: 1 !important;
}
</style>
