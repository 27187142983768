<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <loader v-if="isLoading" />
    <template v-else>
      <admin-header
        v-if="header"
        :title="!isEditPage ? 'Новая камера' : 'Данные камеры'"
        :to="{ name: 'home', query: { tab: 'cameras' } }"
      >
        <sup
          class="text-c13 text-fargo"
          v-if="!isSave && isEditPage"
        >Не сохранено</sup>
      </admin-header>
      <div class="form mt-8 flex flex-1">
        <div class="flex flex-1 flex-column">
          <div class="flex">
            <!-- -->
            <div class="image mr-6">
              <img-uploader
                v-if="!formData.isTrassir"
                @change="onUpload"
                @onViewImage="onViewImage"
                @deleteFile="deleteFile"
                upload-text="Загрузить скриншот"
                :src="formData.isTrassir
                  ? (formData.photo ? `/cityProperty/rest/${formData.photo}` : null)
                  : formData.photo"
              />
              <img
                v-else-if="!!formData.photo"
                :src="formData.photo"
              >
              <r-icon
                v-else
                icon="video-control"
                fill="alien"
                size="32"
              />
            </div>
            <div class="flex-1">
              <r-input
                label="Название"
                v-model.trim="$v.formData.name.$model"
                @blur="isChange('name')"
                :error="$v.formData.name.$error && !$v.formData.name.required"
                error-message="Поле обязательно для заполнения"
              />
              <!-- <div class="flex mt-6">
                <select-search-input
                  label="Группа"
                  class="flex-1"
                  :value="selectedGroup"
                  :items="groupsItems"
                  @change="changeGroup"
                />
                <r-button
                  class="ml-2"
                  @click="addGroup"
                  icon="add"
                  :disabled="!selectedGroup"
                />
              </div>
              <selected-list
                v-show="formData.groups.length"
                class="mt-4"
                icon="telephone"
                v-model="formData.groups"
                sub-value="job"
                text-value="fio"
              />
              -->
            </div>
          </div>
          <r-textarea
            class="mt-6"
            @input="isChange('description')"
            :rows="6"
            label="Описание"
            v-model.trim="formData.description"
          />
          <!--<r-checkbox
            v-model="formData.objectsInFrame"
            title="Объекты в кадре"
            class="mt-6"
            @input="isChange('objectsInFrame')"
          />-->

          <!--       <template v-if="!!formData.isTrassir">
            <h3 class="taleggio mt-10 mb-6">
              Возможности
            </h3>
            <r-checkbox
              v-model="formData.allChoice"
              title="Выбрать все"
              class="mb-6"
              @input="choiceAll"
            />
            <r-checkbox
              v-for="checkbox of defaultAbilities"
              :key="checkbox.icon"
              class="mb-5"
              v-model="formData.abilities"
              :val="checkbox"
              :title="checkbox.value"
              @input="formData.allChoice = false"
              :return-object="true"
            />
          </template>
-->
          <h3 class="taleggio mt-10 mb-6">
            Обслуживающая организация
          </h3>
          <r-input
            label="Название"
            class="mb-6"
            v-model.trim="formData.firm"
            @blur="isChange('firm')"
          />
          <r-input
            label="Адрес"
            class="mb-6"
            v-model.trim="formData.firmAddress"
            @blur="isChange('firmAddress')"
          />
          <r-input
            label="ФИО"
            class="mb-6"
            v-model.trim="formData.fio"
            @blur="isChange('fio')"
          />
          <div class="flex">
            <r-input
              label="Телефон"
              class="flex-1 mr-6"
              v-model.trim="formData.phone"
              @blur="isChange('phone')"
              v-mask="'+7 (###) ###-##-##'"
            />
            <r-input
              label="Имейл"
              class="flex-1"
              v-model.trim="formData.email"
              @blur="isChange('email')"
            />
          </div>
          <!--<r-button
            v-if="isEditPage"
            class="text-fargo sulguni"
            color="transparent"
            contentWidth
            @click="onDelete"
          >
            <r-icon class="mr-2" icon="delete" fill="fargo" />
            Удалить камеру
          </r-button>-->
        </div>
        <div class="ml-6 flex flex-column flex-1 overflow-hidden">
          <rir-map
            @click="onMapClick"
            collapse-btn
            style="height: 415px"
            class="mb-6"
            :center="this.isEditPage
              ? [formData.lat, formData.lng]
              : $cityCenter"
          >
            <!-- :center="this.isEditPage ?
              (formData.isTrassir ? [formData.lat, formData.lng] : [formData.lng, formData.lat]) :
              $cityCenter"-->
            <!-- v-if="!!formData.lat && formData.lng"-->
            <ymap-marker
              :coords="this.isEditPage
                ? [formData.lat, formData.lng]
                : $cityCenter"
              marker-id="marker"
              :options="{
                draggable: this.$store.getters.userIsRoot,
              }"
              :icon="generateIcon(formData.angle)"
              @dragend="onDragMarker"
            />
          </rir-map>
          <r-input
            class="mb-6"
            label="Адрес"
            v-model="$v.formData.address.$model"
            :class="{ field_error: $v.formData.address.$error }"
            :error="$v.formData.address.$error && !$v.formData.address.required"
            error-message="Поле обязательно для заполнения"
            after-icon="search"
            @blur="searchAddress(formData.address)"
            @onPressEnter="searchAddress(formData.address)"
          />
          <r-input
            label="Угол поворота камеры, градусы"
            v-model="formData.angle"
            @blur="isChange('angle')"
          />
        </div>
      </div>
      <div class="button_container">
        <r-button
          width="wide"
          @click="submit"
          :disabled="isLoading || isSave"
          :title="isEditPage ? 'Сохранить' : 'Добавить'"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import RirMap from '../components/RirMap';
import ModalViewImage from '../components/ModalViewImage';
import ImgUploader from '../components/ImgUploader';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import ModalCrop from '../components/ModalCrop';
import { getCameraMarker } from '../helpers/utils';
import Message from '../components/Message';
import Loader from '../components/Loader';
import SaveModal from '../components/SaveModal';

export default {
  name: 'CamerasAdd',
  components: { Loader, AdminHeader, RirMap, ImgUploader },
  props: {
    header: {
      type: Boolean,
      default: true
    },
    camera: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      isSave: true,
      flyButton: '',
      isLoading: false,
      groupsItems: [],
      selectedGroup: null,
      defaultAbilities: [
        {
          id: 1,
          value: 'Распознавание лиц',
          icon: 'employee'
        },
        {
          id: 2,
          value: 'Распознавание автономеров',
          icon: 'minibus'
        },
        {
          id: 3,
          value: 'Распознавание дыма и огня',
          icon: 'new'
        },
        {
          id: 4,
          value: 'Распознавание крупногабаритного транспорта',
          icon: 'technic'
        },
        {
          id: 5,
          value: 'Выдача видео потока',
          icon: 'video-control'
        }
      ],
      initialData: {},
      formData: {
        objectsInFrame: false,
        photo: null,
        name: null,
        description: null,
        address: null,
        lat: null,
        lng: null,
        angle: null,
        groups: [],
        allChoice: false,
        abilities: [],

        firm: null,
        firmAddress: null,
        fio: null,
        phone: null,
        email: null,
        isTrassir: false
      },
      loadedImg: null
    };
  },
  validations: {
    formData: {
      name: {
        required
      },
      address: {
        required
      }
    }
  },
  computed: {
    orgList() {
      return [];
    },
    isEditPage() {
      return !!this.$route.params.id;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  watch: {
    camera: {
      handler(newValue) {
        this.formData = { ...this.formData, ...newValue };
      },
      immediate: true
    }
  },
  async activated() {
    this.resetData();
    this.isShowMap = true;
    this.$v.$reset();
  },
  deactivated() {
    this.isShowMap = false;
    this.resetData();
  },
  mounted() {
    if (!this.isEditPage) {
      this.isSave = false;
    }
    this.$nextTick(() => {
      this.flyingButton();
    });
  },
  methods: {
    generateIcon(angle) {
      return getCameraMarker(angle);
    },
    resetData() {
      this.initialData = JSON.parse(JSON.stringify(this.formData));
      for (const prop in this.formData) {
        this.formData[prop] = this.initialData[prop];
      }
    },
    async onDelete() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить пользователя?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: this.delete
      });
    },
    flyingButton() {
      this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    },
    choiceAll() {
      this.isChange('allChoice');
      if (this.formData.allChoice) {
        this.formData.abilities = this.defaultAbilities;
      } else {
        this.formData.abilities = [];
      }
    },
    changeGroup(item) {
      this.selectedGroup = item;
    },
    addGroup() {
      this.formData.groups.push(this.selectedGroup);
      this.selectedGroup = null;
      this.isChange('groups');
    },
    onViewImage() {
      this.$rir.modal.open(ModalViewImage, {
        file: this.formData.imgUrl
      });
    },
    deleteFile() {
      this.formData.imgUrl = null;
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$rir.modal.open(ModalCrop, {
        image: this.loadedImg,
        valName: 'photo'
      });
      this.isChange('photo');
    },
    async openSaveModal() {
      await this.$rir.modal.open(SaveModal, {
        saveFunc: this.saveData,
        title: this.formData.name
      });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else if (this.isEditPage) {
        this.openSaveModal();
      } else {
        this.saveData();
      }
    },
    async saveData() {
      const data = {
        id: this.isEditPage ? this.$route.params.id : -1,
        action: 'update',
        item: this.formData
      };
      await new Api().operateCamera(data, this.$route.query.trassir).then(res => {
        if (res.error) {
          this.$rir.notification.send({
            title: res.error,
            seconds: 5,
            hiddenClose: false,
            iconOptions: {
              icon: 'warning',
              fill: 'fargo'
            }
          });
        } else if (this.isEditPage) {
          window.location.reload();
        } else {
          const r = this.$router.resolve({
            name: 'index'
          });
          window.location.assign(r.href);
        }
      });
    },
    onChangeOrganization(val) {
      this.isChange('firmId');
    },
    stringToCoords(string) {
      return JSON.parse(`[${string}]`);
    },
    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.formData.coordinates = coords;
      }
    },
    onDragMarker(e) {
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        // this.formData.lat = this.formData.isTrassir ? coords[0] : coords[1];
        // this.formData.lng = this.formData.isTrassir ? coords[1] : coords[0];
        this.formData.lat = coords[0];
        this.formData.lng = coords[1];
        this.searchAddress(coords);
      }
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = this.formData.lat;
        data.lng = this.formData.lng;
      } else {
        data.address = this.formData.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.formData.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.formData.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.formData.lat = res.lat;
        this.formData.lng = res.lng;
      }
      this.isChange('address');
    },
    onChangeCondition(val) {
      this.formData.condition = val;
      this.isChange('condition');
    },
    isChange(field) {
      this.isSave = this.initialData[field] === this.formData[field];
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}
.RButton{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.field_wrapper {
  position: relative;

  .field_error {
    &.RInput__input input {
      background: rgba(#e14761, 0.08);
    }

    &.RTextarea__textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  /*.error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }*/
}

.image {
  width: 105px;
  height: 105px;
  border-radius: 8px;
  background-color: var(--rir-django);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .r-textarea__textarea:focus{
  background: transparent!important;
}
</style>
