<template>
  <div class="not_found" >
    <img src="../assets/not_found_search.svg" alt="">
    <p class="opacity-72 text-center text-c16 color-titanic mt-8px">
      К сожалению, ничего не найдено
    </p>
    <p class="opacity-48 text-center text-c13 color-titanic mt-4px">
      Попробуйте изменить запрос
    </p>
  </div>
</template>

<script>
export default {
  name: "notFound"
}
</script>

<style scoped>
.not_found {
  margin: 100px 0;
  display: grid;
  justify-content: center;
  justify-items: center;
}
</style>
