<template>
  <div>
    <router-link
      class="inline-flex align-center sulguni color-rocky mb-6"
      to="cameras-add"
      v-if="this.$store.getters.userIsRoot"
    >
      <r-icon
        class="mr-2"
        icon="add"
        fill="rocky"
      />
      Добавить камеру
    </router-link>
    <div class="objects__filter">
      <r-select
        class="mr-4"
        :items="sortTypes"
        label="Сортировать"
        v-model="selectedSortType"
      />
      <r-select
        class="mr-4"
        :items="sortCamTypes"
        label="Тип"
        v-model="selectedSortCamType"
        @change="camTypeChange"
      />
      <!--
            <select-checkboxes
              class="mr-4"
              :items="sortOpportunities"
              label="Возможности"
              v-model="selectedSortOpportunities"
            />
            <select-checkboxes
              class="mr-4"
              :items="sortGroups"
              label="Группы"
              v-model="selectedSortGroups"
            >
              <template>
                <router-link
                  class="flex align-center sulguni color-rocky"
                  to="controller-add"
                  style="opacity: 0.72 !important"
                >
                  <r-icon
                    class="mr-2"
                    icon="settings"
                    fill="rocky"
                  />
                  Настроить группы
                </router-link>
              </template>
            </select-checkboxes>
            -->
      <r-input
        class="flex-1"
        label="Поиск"
        v-model="searchText"
        after-icon="search"
      />

      <r-button-simple
        size="larishae"
        class="ml-4"
        icon="icon-view"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
        title=""
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
        title=""
      />
      <!--<r-button
        class="ml-2"
        icon="fullscreen"
        color="secondary"
        icon-size="20"
        @click="openModalVideoWall"
        title=""
      />-->
    </div>

    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
        </div>
      </div>

      <div
        v-if="selectedView === 'map'"
        class="objects__map"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          :balloon-component="balloonCard"
        >
          <ymap-marker
            v-for="marker in filteredObjects"
            :key="`m_${marker.id}`"
            :coords="generateCoords(marker)"
            :marker-id="`marker_${marker.id}`"
            :icon="generateIcon(marker.angle)"
            :options="{
              hideIconOnBalloonOpen: false,
            }"
            :balloon="{ header: { id: marker.id, name: marker.description } }"
            cluster-name="main"
            @balloonopen="bindListener(marker.id)"
            :balloon-component-props="{
              marker,
            }"
          />
        </rir-map>
      </div>
      <cameras-object-list
        v-else
        :filtered-objects="filteredObjects"
        :selected-sort-cam-type="selectedSortCamType"
      />
      <not-found v-if="countObjects < 1" />
    </template>
  </div>
</template>

<script>
import NotFound from '../NotFound';
import SelectCheckboxes from '../SelectCheckboxes';
import ModalVideoWall from '../ModalVideoWall';
import CamerasObjectList from './CamerasObjectList';
import rirMap from '../RirMap';
import BalloonCard from '../BalloonCard';
import Loader from '../Loader';
import { getCameraMarker, wordMatch } from '../../helpers/utils';
import balloonCard from '../BalloonCard.vue';

export default {
  name: 'TabCameras',
  components: {
    NotFound,
    SelectCheckboxes,
    CamerasObjectList,
    rirMap,
    BalloonCard,
    Loader
  },
  data() {
    return {
      selectedView: 'table',
      selectedSortType: 'NAME',
      searchText: '',
      sortTypes: [
        {
          id: 'ADDRESS',
          title: 'По адресу'
        },
        {
          id: 'NAME',
          title: 'По названию'
        }
      ],
      sortCamTypes: [
        {
          id: 'client',
          title: 'Клиентские'
        },
        {
          id: 'trassir',
          title: 'Трассир'
        }
        /* {
           id: 'order',
           title: 'Мои заказы'
         } */
      ],
      selectedSortCamType: 'trassir',
      sortOpportunities: [
        {
          id: 0,
          title: 'Распознавание лиц'
        },
        {
          id: 1,
          title: 'Распознавание автономеров'
        },
        {
          id: 2,
          title: 'Распознавание дыма и огня'
        },
        {
          id: 3,
          title: 'Крупногабаритный транспорт'
        },
        {
          id: 4,
          title: 'Выдача видео потока'
        }
      ],
      selectedSortOpportunities: null,
      sortGroups: [
        {
          id: 0,
          title: 'Пешеходные переходы'
        },
        {
          id: 1,
          title: 'Подъезды'
        },
        {
          id: 2,
          title: 'Скверы'
        },
        {
          id: 3,
          title: 'Тротуары'
        },
        {
          id: 4,
          title: 'Парки и скверы'
        }
      ],
      selectedSortGroups: null,
      isShowMap: false,
      selectedMarker: null
    };
  },
  computed: {
    balloonCard() {
      return balloonCard;
    },
    filteredObjects() {
      let list = Array.isArray(this.$store.state.cameras.cameras) ? this.$store.state.cameras.cameras : [];

      if (this.selectedSortType === 'ADDRESS') {
        // list = list.sort((x, y) => x?.address.localeCompare(y?.address));
        list = list.sort((x, y) => {
          const textA = x?.address?.toUpperCase();
          const textB = y?.address?.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }
      if (this.selectedSortType === 'NAME') {
        // list = list.sort((x, y) => x?.description.localeCompare(y?.description));
        list = list.sort((x, y) => {
          const textA = x?.name.toUpperCase() || x?.description?.toUpperCase();
          const textB = y?.name.toUpperCase() || y?.description?.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.address || '', this.searchText) <= 0.4
            || wordMatch(el?.description || '', this.searchText) <= 0.01
            || wordMatch(el?.name || '', this.searchText) <= 0.01
        );
      }

      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    isLoading() {
      return this.$store.state.cameras.camerasLoading;
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getCameras', this.selectedSortCamType);
  },
  methods: {
    bindListener(id) {
      const balloon = this.$refs.map.querySelector(
        `[data-id-balloon="${id}"]`
      );
      balloon.addEventListener('click', () => {
        this.onBalloonClick(id);
      });
    },
    onBalloonClick(id) {
      this.$router.push({ path: `cameras-details/${id}`, query: { trassir: this.selectedSortCamType === 'trassir' } });
    },
    async camTypeChange(e) {
      console.log('camTypeChange');
      await this.$store.dispatch('getCameras', e);
    },
    generateCoords(marker) {
      if (!!marker.lat && !!marker.lng) {
        return [Number(marker.lat), Number(marker.lng)];
      }
      return null;
    },
    generateIcon(angle) {
      return getCameraMarker(angle);
    },
    async openModalVideoWall() {
      await this.$rir.modal.open(ModalVideoWall);
    }

  }
};
</script>

<style scoped lang="scss">
::v-deep .RButtonSimple {
  width: 40px;
  height: 40px;
}

::v-deep .RButton__icon {
  margin-right: 0;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .r-modal__content {
  padding: 0 !important;
}
</style>
