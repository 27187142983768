<template>
  <div>
    <p class="ricotta">
      Отчёт по автотрафику
    </p>
    <div class="flex align-center mt-6 mb-4">
      <div class="flex align-center">
        <r-popover
          class="mr-6"
          click-close
        >
          <template #activator="{ on }">
            <r-button-action
              @click.native="on.click"
              transparent
              class="mr-2"
              :title="typeList[type]"
              is-arrow
            />
          </template>
          <template #content>
            <r-list-group>
              <template #content>
                <r-list-item
                  v-for="(item,key) of typeList"
                  :key="key"
                  :title="item"
                  @click.native="type = key"
                />
              </template>
            </r-list-group>
          </template>
        </r-popover>
        <r-popover
          class="mr-6"
          click-close
        >
          <template #activator="{ on }">
            <r-button-action
              @click.native="on.click"
              transparent
              class="mr-2"
              :title="timeList[time]"
              is-arrow
            />
          </template>
          <template #content>
            <r-list-group>
              <template #content>
                <r-list-item
                  v-for="(item,key) of timeList"
                  :key="key"
                  :title="item"
                  @click="changeTimeChart(key)"
                />
              </template>
            </r-list-group>
          </template>
        </r-popover>
       <!-- <r-popover>
          <template #activator="{ on }">
            <r-button-action
              @click.native="on.click"
              transparent
              class="mr-2"
              :title="'Все камеры'"
              is-arrow
            />
          </template>
          <template #content>
            <select-search-input
              label="Поиск"
              :value="formData.lessee"
              :items="users"
              @change="changeUser"
              :readonly="!formData.active"
            />
          </template>
        </r-popover>-->
        <r-popover>
          <template #activator="{ on }">
            <r-button-action
              @click.native="on.click"
              transparent
              class="mr-2"
              :title="selectedDate ?
                `${getFormattedDate(selectedDate[0])}–${getFormattedDate(selectedDate[1])}`
                : 'Период'"
              is-arrow
            />
          </template>
          <template #content>
            <r-date-picker
              class="ma-4"
              label="Дата или период"
              inline
              is-clear-model
              period
              v-model="selectedDate"
              @selectDate="changePeriodChart"
            />
          </template>
        </r-popover>
      </div>
    </div>
    <apexcharts
      height="300"

      :type="type"
      :options="chartOptions"
      :series="series"
    />
    <message ref="message" />
  </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts';
import { formattedDate } from '../helpers/utils';
import Api from '../api/Api';
import Message from './Message';

export default {
  name: 'ChartModal',
  components: {
    apexcharts: VueApexCharts,
    Message
  },
  data() {
    return {
      type: 'bar',
      typeList: {
        bar: 'Гистограмма',
        area: 'График'
      },
      time: 'DAY',
      timeList: {
        DAY: 'День',
        WEEK: 'Неделя',
        MONTH: 'Месяц'
      },
      period: 'Период',
      selectedDate: null,

      chartOptions: {
        chart: {
          locales: [{
            name: 'en',
            options: {
              months: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь'
              ],
              shortMonths: [
                'Янв',
                'Фев',
                'Мар',
                'Апр',
                'Май',
                'Июн',
                'Июл',
                'Авг',
                'Сен',
                'Окт',
                'Ноя',
                'Дек'
              ],
              days: [
                'Воскресенье',
                'Понедельник',
                'Вторник',
                'Среда',
                'Четверг',
                'Пятница',
                'Суббота'
              ],
              shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
              toolbar: {
                exportToSVG: 'Сохранить SVG',
                exportToPNG: 'Сохранить PNG',
                exportToCSV: 'Сохранить CSV',
                menu: 'Меню',
                selection: 'Выбор',
                selectionZoom: 'Выбор с увеличением',
                zoomIn: 'Увеличить',
                zoomOut: 'Уменьшить',
                pan: 'Перемещение',
                reset: 'Сбросить увеличение'
              }
            }
          }],
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false,
          opacity: 1
        },
        colors: ['#BB9AF4'],
        yaxis: {
          show: true,
          labels: {
            show: true,
            style: {
              fontSize: '10px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          }
        },
        xaxis: {
          type: 'datetime',
          tickPlacement: 'between',
          labels: {
            offsetX: -5,
            opacity: 0.48,
            rotateAlways: false,
            showDuplicates: false,
            hideOverlappingLabels: false,
            style: {
              colors: ['#04153E'],
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            },
            format: 'dd.MM'
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        noData: {
          text: 'Нет данных за выбранный период',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'RGBA(4,21,62,0.49)',
            fontSize: '16px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif'
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 8
          }
        }
      },
      series: [
        {
          name: 'Трафик',
          data: []
        }
      ],
      measures: []
    };
  },
  created() {
    this.getChartData();
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(date);
    },
    changeTimeChart(key) {
      this.time = key;
      this.getChartData();
    },
    changePeriodChart() {
      this.getChartData();
    },
    async getChartData() {
      await new Api().getTrafficChart({
        sensorId: 'total',
        period: this.time,
        dateFrom: this.selectedDate ? this.selectedDate[0] : '',
        dateTo: this.selectedDate ? this.selectedDate[1] : ''
      }).then(res => {
        if (res.error) {
          this.$refs.message.showMessage(res.error, 'error');
        } else {
          this.series = [{
            data: res?.all?.measures?.map(el => [Math.round(+new Date(el.time) * 1000), el.value])
          }];
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .apexcharts-text tspan{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  opacity: .48;
}
</style>
