<template>
  <div class="d-flex align-items-center justify-content-center">
    <img
      v-if="fullLink"
      class="modal-view-image__view"
      :style="{
        aspectRatio: ratio
      }"
      :src="file"
      alt=""
    >
    <img
      v-else
      class="modal-view-image__view"
      :style="{
        aspectRatio: ratio
      }"
      :src="'/cityProperty/rest/'+file"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: 'ModalViewImage',
  props: {
    fullLink: {
      type: Boolean,
      default: false
    },
    file: {
      type: String,
      default: ''
    },
    ratio: {
      type: String,
      default: '16/10'
    }
  }
};
</script>

<style lang="scss">
.modal-view-image{
  &__view{
    max-width: 100%;
  }
}
</style>
