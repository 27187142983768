import Api from '@/api/Api';
import { formattedDate } from '../../helpers/utils';

const api = new Api();
export default {
  state: () => ({
    minePlates: [],
    platesLoading: true
  }),
  mutations: {
    setMinePlates(state, res) {
      state.minePlates = res || [];
    },
    setLoading(state, bool) {
      state.platesLoading = bool;
    }
  },
  actions: {
    async getMinePlates(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getCarPlates();
      ctx.commit('setMinePlates', res);
      ctx.commit('setLoading', false);
      return res;
    },
    async removeFromWantedList(ctx, trackingId) {
      const res = await new Api().deleteMonitoring(trackingId)
      return res;
    },
    async putToWantedList(ctx, plate) {
      const res = await new Api().addMonitoringPlate(plate);
      return res;
    }
  },
  getters: {
    getFormattedMinePlates(state) {
      return state?.minePlates?.map(el => ({
        id: el.id,
        hidden: el?.hidden,
        columns: {
          plate: el.plate,
          hit: {
            title: el.hit,
            subtitle: el.hitLastReport
          },
          date: {
            title: formattedDate(el.createdAt),
            subtitle: formattedDate(el.createdAt, {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric'
            })
          },
          comment: '-'
        }
      }));
    },
    getMinePlatesById: state => id => state?.minePlates?.find(el => el.id === Number(id))
  }
};
