<template>
  <div>
    <admin-header
      title="Поиск номеров"
      class="mb-7"
      :to="{ name: `admin`, query: { tab: 'car-plates'}}"
    />
    <div class="flex align-center">
      <r-date-picker
        label="Дата или период"
        period
        class="flex-1 mr-6"
        v-model="selectedDate"
      />
      <div class="relative flex-1 input_tooltip">
        <r-input
          label="Госномер"
          v-model.trim="searchPlate"
        />
        <r-tooltip
          class="absolute"
          activator-hover
          color="amelie"
          title="Используйте только латиницу. Вместо любого значения можно ввести символ *"
        >
          <template #activator>
            <r-icon
              icon="help"
              fill="titanic"
              class="pointer opacity-48"
            />
          </template>
        </r-tooltip>
      </div>
      <r-button-simple
        size="larishae"
        class="ml-4"
        icon="icon-view"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <div class="flex align-center mt-6 mb-6">
      <r-button-action
        v-if="list.length && !$v.$invalid"
        @click="putWantedList"
        transparent
        :title="searchPlateValid ? 'Отслеживать номер' : 'Отслеживать группу номеров'"
        icon="new-folder"
      />
      <div class="parmigiano text-titanic opacity-48 ml-auto">
        {{ !!searchPlate ? 'Найдено' : 'Всего' }} {{ countObjects }}
      </div>
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <vue-table
        :list="list"
        :headings="headings"
        :columns-grid="tableGridColumns"
        class="rows_space-between "
        style="padding-bottom: 100px;"
        @statusSelected="changeStatus"
      />
      <template v-if="history.length">
        <p class="mozzarella mb-6 mt-6 opacity-48">
          История запросов
        </p>
        <div class="history">
          <div
            @click="historyAddSearch(item)"
            class="history__item"
            v-for="item of history"
            :key="item.id"
          >
            <span class="sulguni opacity-72 block">{{ item.request.plate }}</span>
            <span class="briscola opacity-48 block">{{ getFormattedDate(item.created) }}</span>
          </div>
        </div>
      </template>
    </template>

    <message ref="message" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import VueTable from '../components/Table';
import AdminHeader from '../components/AdminHeader';
import Api from '../api/Api';
import { formattedDate, validateCarPlate } from '../helpers/utils';
import Message from '../components/Message';
import Loader from '../components/Loader';

const dateNow = new Date();
const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
const dateNowBackMonth = new Date(dateNow.setMonth(dateNow.getMonth() - 1));

export default {
  name: 'FindCarPlate',
  components: { Loader, VueTable, AdminHeader, Message },
  data() {
    return {
      selectedDate: [
        dateNowBackMonth.toLocaleDateString('en-CA', options),
        new Date().toLocaleDateString('en-CA', options)
      ],
      searchPlate: '',
      selectedView: 'table',
      headings: [
        {
          title: 'Скриншот',
          field: 'photo'
        },
        {
          title: 'Госномер',
          field: 'number'
        },
        {
          title: 'Дата',
          field: 'date'
        },
        {
          title: 'Камера',
          field: 'Camera'
        },
        {
          title: 'Статус',
          field: 'status'
        }
      ],
      tableGridColumns: ['103px', '100px', '90px', '400px', '170px'],
      list: [],
      isLoading: false,
      searchPlateValid: false,
      history: []
    };
  },
  validations: {
    searchPlate: {
      required
    }
  },
  computed: {
    filteredObjects() {
      const { list } = this;
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    }
  },
  watch: {
    searchPlate: _.debounce(function (newVal) {
      this.searchPlateValid = validateCarPlate(newVal);
      this.searchPlate = newVal;
      this.findPlate();
    }, 1000)
  },
  async created() {
    await this.$store.dispatch('getMinePlates');
    await new Api().getCamerasLog().then(res => {
      if (!res.error) {
        this.history = res.all;
      } else {
        this.$refs.message.showMessage(res.error, 'error');
      }
    });
  },
  methods: {
    historyAddSearch({ request }) {
      this.searchPlate = request.plate;
      this.selectedDate = [
        request.dateFrom,
        request.dateTo
      ];
    },
    getFormattedDate(date) {
      return formattedDate(date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
    },
    changeStatus({ status, trackingId, plate }) {
      if (trackingId === null && status === '1') {
        this.putWantedList(plate);
      } else {
        this.removeWantedList(trackingId);
      }
    },
    async removeWantedList(trackingId) {
      await this.$store.dispatch('removeFromWantedList', trackingId).then(res => {
        if (!res.error) {
          this.$refs.message.showMessage('Номер удален из отслеживания', 'success');
        } else {
          this.$refs.message.showMessage(res.error, 'error');
        }
      });
    },
    async putWantedList(plate = this.searchPlate) {
      await this.$store.dispatch('putToWantedList', plate).then(res => {
        if (!res.error) {
          this.$refs.message.showMessage('Номер добавлен в отслеживание', 'success');
        } else {
          this.$refs.message.showMessage(res.error, 'error');
        }
      });
    },
    findPlate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('err');
      } else {
        this.isLoading = true;
        new Api().sendToTrassir({
          casse: 1,
          plate: this.searchPlate,
          dateFrom: this.selectedDate[0],
          dateTo: this.selectedDate[1],
          Q: ''
        }).then(res => {
          if (res.error) {
            this.$refs.message.showMessage(res.error, 'error');
          } else {
            this.list = res?.map(el => ({
              id: el.surrogateKey,
              columns: {
                photo: `/trassir/rest/scr/${el.surrogateKey}/thumb`,
                number: el.plate,
                date: {
                  title: formattedDate(Number(el.time_bestview) * 1000),
                  subtitle: formattedDate(Number(el.time_bestview) * 1000, {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                  })
                },
                camera: {
                  title: el.screen?.channel.name,
                  subtitle: el.screen?.channel.address
                },
                status: this.getPlateStatus(el.plate)
              }
            }));
          }
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    getPlateStatus(plate) {
      const matchPlate = this.$store.state.plates.minePlates.find(el => el.plate === plate && !el.hidden);
      return {
        value: !!matchPlate,
        trackingId: matchPlate?.id || null,
        plate
      };
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .RButtonSimple {
  width: 40px;
  height: 40px;
}

.history {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 24px;

  &__item {
    cursor: pointer;
    * {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      * {
        color: #214eb0;
      }
    }
  }
}

::v-deep .r-tooltip {
  display: flex;
  align-items: center;
}

::v-deep .r-tooltip.hover:hover > .r-tooltip__wrapper--arrow {
  display: none !important;
}

::v-deep .r-tooltip__wrapper {
  bottom: calc(100% + 6px);
  right: 0;
  top: unset !important;
  left: unset !important;
  max-width: 260px;
  text-align: left;
}

.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

.input_tooltip {
  .absolute {
    position: absolute;
    top: 12px;
    right: 16px;
  }

  ::v-deep .RInput {
    padding-right: 40px;
  }
}
</style>
