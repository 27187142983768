<template>
  <r-popover
    class="relative"
    :clickClose="true"
    :is-open="isOpen"
    @input="onChangeShow"
    :class="{'color-matrix': selectedItem, 'color-titanic': !selectedItem}"
  >
    <template #activator="{ on }">
      <r-button-action
        @click.native="on.click"
        :isArrow="true"
        class="mr-2"
        :color="selectedItem ? 'matrix': 'harakiri'"
        :title="items[Number(selectedItem)]"
      />
    </template>

    <template #content>
      <div class="list">
        <template v-for="(item, key) in items">
          <div
            class="item"
            :class="{selected: Number(selectedItem) === Number(key)}"
            @click="selectItem(key)"
            :key="key"
          >
            <span class="sulguni color-titanic">{{ item }}</span>
          </div>
        </template>
      </div>
    </template>
  </r-popover>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownButton',
  directives: {
    ClickOutside
  },
  props: {
    selected: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: false,
      selectedItem: 0
    };
  },
  created() {
    this.selectedItem = Number(this.$props.selected.value);
  },
  methods: {
    onChangeShow(val) {
      this.isOpen = val;
    },
    clickOutside() {
      // if (this.isOpen && e.target.closest('.btn-dropdown-list') === null) {
      this.isOpen = false;
      // }
    },
    selectItem(status) {
      this.$emit('select', {
        status,
        trackingId: this.$props.selected.trackingId,
        plate: this.$props.selected.plate
      });
      this.selectedItem = Number(status);
      this.isOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}

// .disabled {
//   opacity: 0.72;
// }
.list {
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;

  box-shadow: 0 9px 24px rgba(17, 48, 121, 0.18);
  z-index: 10000;
  border-radius: 8px;
}

.item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  opacity: 0.72;
  padding: 14px 16px;
  position: relative;

  &:hover,
  &.selected{
    opacity: 1;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      border-left: 4px solid var(--rir-rocky);
    }
  }
}

::v-deep .r-drop-down__content {
  top: 100%;
  left: 0;
}
::v-deep .r-drop-down__activator{
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
::v-deep .r-drop-down__activator > i{
  display: block!important;
  margin-right: 0;
  svg,
  path{
    fill: currentColor;
  }
}
</style>
