<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <loader v-if="isLoading" />
    <template v-else>
      <admin-header
        v-if="header"
        :title="!isEditPage ? 'Новый гражданин' : 'Данные гражданина'"
      >
        <sup
          class="text-c13 text-fargo"
          v-if="!isSave && isEditPage"
        >Не сохранено</sup>
      </admin-header>
      <div class="form mt-8 flex flex-1">
        <div class="flex flex-1 align-start">
          <div class="field_wrapper">
            <img-uploader
              class="mr-6"
              @change="onUpload"
              @onViewImage="onViewImage"
              @deleteFile="deleteFile"
              upload-text="Загрузить фото"
              :src="formData.Image0"
              :class="{ field_error: $v.formData.Image0.$error }"
            />
            <div
              class="error mozzarella text-fargo bottom"
              v-if="$v.formData.Image0.$error && !$v.formData.Image0.required"
            >
              Поле обязательно для заполнения
            </div>
          </div>
          <div class="mr-6 flex-1">
            <r-input
              label="ФИО"
              class="mb-6 flex-1"
              v-model.trim="$v.formData.Name.$model"
              @blur="isChange('Name')"
              :class="{ field_error: $v.formData.Name.$error }"
              :error="$v.formData.Name.$error && !$v.formData.Name.required"
              error-message="Поле обязательно для заполнения"
            />
            <r-date-picker
              label="Дата рождения"
              class="flex-1 mb-6"
              v-model="formData.Birthdate"
              @change="isChange('Birthdate')"
              :class="{ field_error: $v.formData.Birthdate.$error }"
              :error="$v.formData.Birthdate.$error && !$v.formData.Birthdate.required"
              error-message="Поле обязательно для заполнения"
            />
            <r-select
              class="flex-1"
              @change="isChange('Gender')"
              :items="[
                {
                  id: '1',
                  title: 'Мужской'
                },
                {
                  id: '2',
                  title: 'Женский'
                }
              ]"
              label="Пол"
              v-model="formData.Gender"
              :class="{ field_error: $v.formData.Gender.$error }"
              :error="$v.formData.Gender.$error && !$v.formData.Gender.required"
              error-message="Поле обязательно для заполнения"
            />
          </div>
          <r-textarea
            class="flex-1"
            @input="isChange('Comment')"
            :rows="7"
            label="Комментарий"
            v-model.trim="formData.Comment"
          />
        </div>
      </div>
      <div
        class="error my-6 mozzarella text-fargo"
      >
        {{ submitError }}
      </div>
      <div class="button_container">
        <r-button
          @click="submit"
          :disabled="isLoading || isSave"
          width="wide"
          :title="isEditPage ? 'Сохранить' : 'Добавить' "
        />
      </div>
    </template>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import ModalViewImage from '../components/ModalViewImage';
import ImgUploader from '../components/ImgUploader';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import ModalCrop from '../components/ModalCrop';
import { getCameraMarker } from '../helpers/utils';
import Loader from '../components/Loader';

export default {
  name: 'Person',
  components: { AdminHeader, ImgUploader, Loader },
  props: {
    header: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      loadedImg: null,
      isSave: true,
      flyButton: '',
      isLoading: false,
      initialData: {},
      submitError: '',
      formData: {
        Image0: null,
        Name: null,
        Gender: null,
        Birthdate: null,
        Comment: '',
        ContactInfo: ''
      }
    };
  },
  validations: {
    formData: {
      Image0: {
        required
      },
      Name: {
        required
      },
      Gender: {
        required
      },
      Birthdate: {
        required
      }
    }
  },
  computed: {
    isEditPage() {
      return !!this.$route.params.id;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  created() {
    if (this.$props.data) {
      this.resetData();
      this.formData = { ...this.formData, ...this.$props.data };
    }
  },
  async activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData(this.$route.params.id);
    this.isShowMap = true;
    this.$v.$reset();
  },
  deactivated() {
    this.isShowMap = false;
  },
  mounted() {
    if (!this.isEditPage) {
      this.isSave = false;
      this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    }
  },
  methods: {
    resetData() {
      this.initialData = JSON.parse(JSON.stringify(this.formData));
      for (const prop in this.formData) {
        this.formData[prop] = this.initialData[prop];
      }
    },
    async setData(id) {
      this.isLoading = true;
      if (id) {
        await new Api().wantedVideo({ action: 'profile', guid: this.$route.params.id }).then(res => {
          this.formData = { ...this.formData, ...res };
          this.isLoading = false;
        });
      }
    },
    async onDelete() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить пользователя?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: this.delete
      });
    },
    choiceAll() {
      if (this.formData.allChoice) {
        this.formData.opportunities = this.defaultOpportunities;
      } else {
        this.formData.opportunities = [];
      }
    },
    changeGroup(item) {
      this.selectedGroup = item;
    },
    addGroup() {
      this.formData.groups.push(this.selectedGroup);
      this.selectedGroup = null;
      this.isChange('groups');
    },
    onViewImage() {
      this.$rir.modal.open(ModalViewImage, {
        file: this.formData.Image0
      });
    },
    deleteFile() {
      this.formData.Image0 = null;
    },
    onUpload(file, target = null) {
      const formData = new FormData();
      formData.append('privateUserId', this.$store.state.userId);
      formData.append('upload_url', 'temp');
      formData.append('files[]', file);
      new Api().uploadImage(formData).then(res => {
        this.formData.Image0 = res.files[0].originalName;
      });
      /* // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$rir.modal.open(ModalCrop, {
        image: this.loadedImg,
        valName: target === 'staff' ? 'contactImg' : 'imgUrl',
        portrait: target === 'staff'
      }); */
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const data = {
          action: 'addPerson',
          item: {
            guid: this.isEditPage ? this.$route.params.id : '',
            details: {
              ...this.formData,
              Birthdate: new Date(this.formData.Birthdate).toLocaleDateString()
            }
          }

        };
        await new Api().wantedVideo(data).then(res => {
          console.log(res);
          if (res.error) {
            this.submitError = res.error;
          } else if (this.isEditPage) {
            window.location.reload();
          } else {
            const r = this.$router.resolve({
              name: 'index'
            });
            window.location.assign(r.href);
          }
        });
      }
    },

    isChange(field) {
      this.isSave = this.initialData[field] === this.formData[field];
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    ::v-deep .av-uploader__upload {
      background: var(--rir-schindler);
    }

    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  ::v-deep .av-uploader {
    & + .error {
      left: 0 !important;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

.image {
  width: 105px;
  height: 105px;
  border-radius: 8px;
  background: #e4edfb;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .r-textarea__textarea:focus {
  background: transparent !important;
}

::v-deep .r-textarea {
  min-height: 167px;
}
</style>
