<template>
  <div>
    <loader v-if="loading" />
    <template v-else>
      <img

        alt="src"
        ref="image"
        v-if="!errorImage"
        @error="errorImage = true"
      >
      <r-icon
        v-else
        icon="camera-cctv"
        size="32"
        fill="rocky"
      />
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Loader from './Loader';
import Api from '../api/Api';

export default {
  name: 'LoadImage',
  components: {
    Loader
  },
  props: {
    src: {
      type: String,
      required: true
    },
    status: {
      type: String
    }
  },
  data() {
    return {
      loading: true,
      interval: null,
      errorImage: true
    };
  },
  async mounted() {
    const observer = new IntersectionObserver(async entries => {
      if (entries[0].isIntersecting) {
        await this.loadImage();
      }
    });
    observer.observe(this.$el);
  },
  methods: {
    // async getUrlImage() {
    //   if (this.status === 'REQUESTED') {
    //     await axios.get(this.src).then(res => {
    //       console.log(res);
    //       if (res.data.status === 'REQUESTED') {
    //         this.$refs.image.src = `/trassir/rest/scr/${res.data.id}/thumb`;
    //       } else {
    //         this.$refs.image.src = res.url;
    //       }
    //       this.errorImage = false;
    //     }).finally(() => {
    //       this.loadImage();
    //     });
    //   } else {
    //     this.loadImage();
    //   }
    // },
    loadImage() {
      const img = new Image();
      img.src = this.src;

      img.onload = () => {
        this.loading = false;
        this.errorImage = false;
        this.$refs.image.src = img.src;
      };
    },

  }
};
</script>

<style scoped>

img {
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
}
</style>
