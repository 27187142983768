<template>
  <div>
    <loader v-if="isLoading" />
    <template v-else>
      <admin-header :title="formData.description || 'Данные камеры'" />
      <p class="feta opacity-72 flex align-center mt-3">
        <r-icon
          icon="geopoint"
          size="16"
          fill="titanic"
          class="mr-2 opacity-72"
        />
        {{ formData.address || 'Не указан' }}
      </p>
      <r-tabs
        class="mt-7 mb-6"
        :items="tabs"
        v-model="activeTabId"
      />
      <div v-if="activeTabId.id === 'video'">
        <!--<r-button-action
          class="mb-2"
          @click="openArchiveVideoModal"
          title="Архив видеозаписей"
          icon="archive"
        />-->
        <video-stream
          :stop-video="activeTabId.id !== 'video'"
          :video="formData.url || formData.stream"
          :id="formData.id"
        />
        <!--<r-player-video :url="formData.url || formData.stream"/>-->
      </div>
      <!-- <cameras-details-persons-tab v-if="activeTabId === 'persons'" />
      <cameras-details-car-plate-tab v-if="activeTabId === 'car-plates'" />-->
      <cameras-details-sensors-tab v-if="activeTabId.id === 'sensors'" />

      <cameras
        v-if="activeTabId.id === 'settings'"
        :header="false"
        :camera="formData"
      />
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import ModalViewImage from '../components/ModalViewImage';
import DeleteModal from '../components/DeleteModal';
import VideoStream from '../components/VideoStream';
import ArchiveVideoModal from '../components/ArchiveVideoModal';
import CamerasDetailsPersonsTab from '../components/Cameras/CamerasDetailsPersonsTab';
import CamerasDetailsCarPlateTab from '../components/Cameras/CamerasDetailsCarPlateTab';
import CamerasDetailsSensorsTab from '../components/Cameras/CamerasDetailsSensorsTab';
import Cameras from './Cameras';
import Api from '../api/Api';
import Loader from '../components/Loader';

export default {
  name: 'CamerasDetails',
  components: {
    AdminHeader,
    VideoStream,
    CamerasDetailsPersonsTab,
    CamerasDetailsCarPlateTab,
    CamerasDetailsSensorsTab,
    Cameras,
    Loader
  },
  data() {
    return {
      activeTabId: {
        id: 'video',
        title: 'Видео'
      },
      tabs: [
        {
          id: 'video',
          title: 'Видео'
        },
        /*  {
          id: 'persons',
          value: 'Персоны'
        },
        {
          id: 'car-plates',
          value: 'Автономера'
        }, */

        {
          id: 'settings',
          title: 'Настройки'
        }
      ],
      isSave: true,
      flyButton: '',
      isLoading: false,
      groupsItems: [],
      selectedGroup: null,
      defaultOpportunities: [
        {
          value: 'Распознавание лиц',
          icon: 'employee'
        },
        {
          value: 'Распознавание автономеров',
          icon: 'minibus'
        },
        {
          value: 'Распознавание дыма и огня',
          icon: 'new'
        },
        {
          value: 'Распознавание крупногабаритного транспорта',
          icon: 'technic'
        },
        {
          value: 'Выдача видео потока',
          icon: 'video-control'
        }
      ],
      initialData: {},
      camera: {},
      formData: {
        objectsInFrame: false,
        photo: null,
        title: null,
        description: null,
        address: null,
        lat: null,
        lng: null,
        angle: null,
        groups: [],
        allChoice: false,
        opportunities: [],

        firm: null,
        firmAddress: null,
        fio: null,
        phone: null,
        email: null
      }
    };
  },
  computed: {
    isEditPage() {
      return !!this.$route.params.id;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  mounted() {},
  async activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && await this.setData(this.$route.params.id);
  },

  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    resetData() {
      this.initialData = JSON.parse(JSON.stringify(this.formData));
      for (const prop in this.formData) {
        this.formData[prop] = this.initialData[prop];
      }
    },
    async setData(id) {
      this.isLoading = true;
      if (id) {
        await new Api().getCamerasById(id, this.$route.query.trassir).then(res => {
          this.formData = { ...this.formData, ...res };
        }).finally(() => {
          this.isLoading = false;
          if (this.formData.objectsInFrame === true) {
            if (this.tabs[1].id !== 'sensors') {
              this.tabs.splice(1, 0, {
                id: 'sensors',
                title: 'Объекты в кадре'
              });
            }
          }
        });
      }
    },
    async openArchiveVideoModal() {
      this.$rir.modal.open(ArchiveVideoModal, {
        item: this.formData
      });
    },
    async onDelete() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить пользователя?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: this.delete
      });
    },
    choiceAll() {
      if (this.formData.allChoice) {
        this.formData.opportunities = this.defaultOpportunities;
      } else {
        this.formData.opportunities = [];
      }
    },
    changeGroup(item) {
      this.selectedGroup = item;
    },
    addGroup() {
      this.formData.groups.push(this.selectedGroup);
      this.selectedGroup = null;
      this.isChange('groups');
    },
    onViewImage() {
      this.$rir.modal.open(ModalViewImage, {
        file: this.formData.imgUrl
      });
    },
    deleteFile() {
      this.formData.imgUrl = null;
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$rir.modal.open(ModalCrop, {
        image: this.loadedImg,
        valName: target === 'staff' ? 'contactImg' : 'imgUrl',
        portrait: target === 'staff'
      });
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        /*
        *   let r = this.$router.resolve({
            name: 'index',
            query: {
              tab: 'statements'
            }
          });
          window.location.assign(r.href) */
      }
    },
    onChangeOrganization(val) {
      console.log(val);
      this.isChange('firmId');
    },
    stringToCoords(string) {
      return JSON.parse(`[${string}]`);
    },
    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.formData.coordinates = coords;
      }
    },
    onDragMarker(e) {
      this.formData.coordinates = e.get('target').geometry._bounds[0];
    },
    onChangeCondition(val) {
      this.formData.condition = val;
      this.isChange('condition');
    },
    isChange(field) {
      /* if (this.initialData[field] === this.formData[field]) {
         this.isSave = true;
       } else {
         this.isSave = false;
       } */
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}
</style>
