<template>
  <div
    class="r-map-balloon pointer flex flex-column"
  >
    <div class="r-map-balloon__img">
      <img
        :src="imageUrl"
        :alt="marker.name"
        v-if="!errorImage"
        @error="errorImage = true"
      >
      <r-icon
        v-else
        icon="camera-cctv"
        size="32"
        fill="rocky"
      />
    </div>
    <div class="pa-4">
      <p class="roquefort mb-1">
        {{ marker.name }}
      </p>
      <p class="сaprino opacity-72">
        {{ marker.address }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'BalloonCard',
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      errorImage: true,
      imageUrl: null
    };
  },
  async created() {
    await this.getUrlImage();
  },
  methods: {
    async getUrlImage() {
      const token = window.token ? window.token : await window?.getToken;
      await axios.get(`/trassir/rest/cameras/${this.marker.id}/image`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const blob = new Blob([response.data]);
          this.imageUrl = URL.createObjectURL(blob);
          this.errorImage = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;

  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4EDFB;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__info {
    padding: 12px 16px 0;
  }
}

</style>
