<template>
  <div class="table">
    <div
      class="table_head"
      v-if="headings.length > 0"
      ref="tableHead"
    >
      <div
        class="table_row mozzarella"
        :style="
          !!columnsGrid
            ? 'grid-template-columns:' + columnsGrid.join(' ')
            : 'grid-auto-flow:column;'
        "
      >
        <div
          class="table_col flex align-center opacity-72"
          :class="{
            'opacity-48': head.field === 'screenshot',
            pointer: head.field !== 'screenshot',
          }"
          v-for="(head, index) of headings"
          :key="'head' + index"
        >
          <!--@click="head.field === 'screenshot' ? null : (sorting *= -1), (sortingType = head.field)"-->
          {{ head.title }}
          <!--<svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="head.field !== 'screenshot'"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.37829 11.835C8.16934 12.055 7.83058 12.055 7.62163 11.835L5.15665 9.96155C4.94771 9.74158 4.94771 9.38494 5.15665 9.16498C5.3656 8.94501 5.70436 8.94501 5.91331 9.16498L7.99996 10.6402L10.0866 9.16497C10.2956 8.94501 10.6343 8.94501 10.8433 9.16497C11.0522 9.38494 11.0522 9.74158 10.8433 9.96154L8.37829 11.835Z"
              fill="#C0D6F6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.6217 4.16497C7.83064 3.94501 8.16939 3.94501 8.37832 4.16497L10.8433 6.03846C11.0522 6.25842 11.0522 6.61506 10.8433 6.83503C10.6344 7.05499 10.2956 7.05499 10.0867 6.83503L8.00001 5.35983L5.91332 6.83503C5.70439 7.05499 5.36564 7.05499 5.1567 6.83503C4.94777 6.61506 4.94777 6.25842 5.1567 6.03846L7.6217 4.16497Z"
              fill="#C0D6F6"
            />
          </svg>-->
        </div>
      </div>
    </div>
    <div
      class="table_body"
      v-if="filteredList.length"
    >
      <router-link
        :is="notLinkRow ? 'span' : 'router-link'"
        :to="rowLink ? (rowLink + row.id) : '#'"
        class="table_row"
        v-for="(row, index) of filteredList"
        :key="`${row.id}-table_row--${index}`"

        :style="
          !!columnsGrid
            ? 'grid-template-columns:' + columnsGrid.join(' ')
            : 'grid-auto-flow:column;'
        "
      >
        <!--
      :class="{ pointer: !!row.screenshot, 'hidden': row.hidden }"
        @click="!!row.screenshot ? openModalViewImage(row.screenshot) : null"-->
        <div
          class="table_col feta"
          :key="row.id + 'table_col' + colKey"
          v-for="(col, colKey) of row.columns"
          :class="{overflow: colKey !== 'status'}"
        >
          <template v-if="colKey === 'screenshot'">
            <div
              class="table_image pointer"
              :class="{'circle' : circleScreenshot}"
              v-if="lazyLoad"
              @mouseover="lazyLoadImage($event, row.id)"
            >
              <img
                :src="col"
                alt=""
                v-if="!!col"
              >
              <r-icon
                v-else
                icon="video-control"
                fill="alien"
                class=""
              />
            </div>
            <div
              class="table_image"
              :class="{'circle' : circleScreenshot}"
              v-else
            >
              <div
                v-if="!!col"
                @click.stop.prevent="openModalViewImage(row.screenshot, true )"
              >
                <load-image
                  class="pointer"
                  :src="col"
                  :status="row.status"
                  alt=""
                />
              </div>

              <r-icon
                v-else
                icon="video-control"
                fill="alien"
                class=""
              />
            </div>
          </template>
          <template v-else-if="colKey === 'photo'">
            <div
              class="table_image"
              :class="{'circle' : circleScreenshot}"
            >
              <img
                :src="col"
                alt=""
                v-if="!!col"
              >
              <r-icon
                v-else
                icon="video-control"
                fill="alien"
                class=""
              />
            </div>
          </template>
          <r-button-action
            v-else-if="colKey === 'videoView'"
            class="color-rocky sulguni opacity-72 play_video_button"
            color="rocky"
            content-width
            @click.prevent.stop.native="openArchiveVideoModal(row)"
            title="Смотреть видео"
            icon="play"
          />
          <r-button-action
            v-else-if="colKey === 'videoDownload'"
            class="color-rocky sulguni opacity-72 play_video_button"
            content-width
            @click.native.stop.prevent="downloadVideo(row.id)"
            title="Скачать видео"
            icon="load"
            color="rocky"
          />
          <template v-else-if="colKey === 'monitored'">
            <r-button-action
              transparent
              @click.native.stop.prevent="changeMonitored(col, row)"
              v-if="!col"
              title="Поставить"
            />
            <p
              v-else
              class="color-matrix"
            >
              Отслеживается
            </p>
          </template>
          <template v-else-if="colKey === 'deleteMonitored'">
            <r-button-action
              transparent
              @click.native.stop.prevent="changeMonitored(col, row)"
              class="color-fargo"
              title="Убрать"
              color="fargo"
            />
          </template>
          <template v-else-if="colKey === 'hit'">
            <p>
              {{ col.title }}
              <sup
                class="color-matrix сaprino "
                v-if="col.subtitle > 0"
              >
                +{{ col.subtitle }}
              </sup>
            </p>
          </template>
          <template v-else-if="colKey === 'status'">
            <drop-down-button
              @click.native.prevent.stop
              :selected="col"
              @select="statusSelected"
              :items="{
                0: 'Не отслеживаю',
                1: 'Отслеживаю'
              }"
            />
          </template>

          <template v-else>
            <span v-html="(!!col ? col.title || col : '-') + getSubtitle(col)" />
          </template>
        </div>
        <div
          class="table_col justify-self-end"
          v-if="downloadButton || archiveButton"
        >
          <additional-menu>
            <ul
              class="card__menu"
              style="display: grid; grid-gap: 24px;"
            >
              <li
                v-if="downloadButton"
                class="sulguni flex pointer opacity-72 align-center nowrap"
                @click.stop.prevent="orderVideo(row.channelId, row.timestamp)"
              >
                <r-icon
                  class="mr-3"
                  icon="load"
                  fill="rocky"
                />
                Скачать видео
              </li>
              <li
                v-if="statusButton"
                class="sulguni flex pointer opacity-72 align-center nowrap"
                @click.stop.prevent="changeStatus(row.status)"
              >
                <template v-if="row.status.value">
                  <r-icon
                    class="mr-3"
                    icon="no-vision"
                    fill="fargo"
                  />
                  Не отслеживать
                </template>
                <template v-else>
                  <r-icon
                    class="mr-3"
                    icon="eye"
                    fill="matrix"
                  />
                  Отслеживать
                </template>
              </li>
            </ul>
          </additional-menu>
        </div>
      </router-link>
    </div>
    <template v-else>
      <not-found v-if="!infiniteLoading" />
    </template>
    <!--    <template v-else>-->
    <!--      <infinite-loading-->
    <!--        v-if="infiniteLoading"-->
    <!--        @infinite="$emit('infiniteHandler', $event)"-->
    <!--        ref="infinite"-->
    <!--      >-->
    <!--        <div slot="spinner">-->
    <!--          <div class="flex justify-content-center my-3">-->
    <!--            <r-spinner />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div slot="no-more" />-->
    <!--        <div slot="no-results">-->
    <!--          <not-found />-->
    <!--        </div>-->
    <!--      </infinite-loading>-->
    <!--      <not-found v-else />-->
    <!--    </template>-->

    <message ref="message" />
  </div>
</template>

<script>
import infiniteLoading from 'vue-infinite-loading';
import ModalViewImage from './ModalViewImage';
import ArchiveVideoModal from './ArchiveVideoModal';
import Api from '../api/Api';
import notFound from './NotFound';
import dropDownButton from './DropDownButton';
import AdditionalMenu from './AdditionalMenu';
import Message from './Message';
import LoadImage from './LoadImage';

export default {
  name: 'VueTable',
  components: {
    notFound,
    dropDownButton,
    AdditionalMenu,
    Message,
    infiniteLoading,
    LoadImage
  },
  props: {
    notLinkRow: {
      type: Boolean,
      default: false
    },
    infiniteLoading: {
      type: Boolean,
      default: false
    },
    lazyLoad: {
      type: Boolean,
      required: false
    },
    circleScreenshot: {
      type: Boolean,
      required: false
    },
    headings: {
      type: [Array, Object],
      default: () => []
    },
    list: {
      type: Array,
      required: true
    },
    columnsGrid: {
      type: Array
    },
    rowLink: {
      type: String,
      default: ''
    },
    downloadButton: {
      type: Boolean,
      default: false
    },
    statusButton: {
      type: Boolean,
      default: false
    },
    archiveButton: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      rows: [],
      sorting: -1,
      sortingType: ''
    };
  },
  computed: {
    filteredList() {
      let { list } = this.$props;
      if (this.sortingType) {
        list = list.sort((a, b) => (a[this.sortingType] < b[this.sortingType] ? this.sorting : -this.sorting));
      }
      return list;
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    statusSelected(data) {
      this.$emit('statusSelected', data);
    },
    async changeMonitored(status, user) {
      await new Api().wantedVideo({
        action: status ? 'action2' : 'action1',
        guid: user?.id,
        trackingId: user?.trackingId || '',
        name: user?.columns?.name
      });
    },
    async lazyLoadImage(e, guid) {
      const hoverElement = this.filteredList.find(el => el.id === guid);
      if (!hoverElement.columns.screenshot) {
        await new Api().wantedVideo({
          action: 'shot', guid, isEvent: false
        }).then(res => {
          hoverElement.columns.screenshot = `data:image/jpeg;base64,${res?.image}` || '';
        });
      }
    },
    async openArchiveVideoModal(row) {
      console.log(row);
      this.$rir.modal.open(ArchiveVideoModal, {
        item: {
          name: row.screenshot.channel.name,
          timestamp: row.timestamp,
          id: row.screenshot.channel.id,
          guid: row.screenshot.channel.guid,
          stream: `/trassir/rest/archive/stream/${row.screenshot.channel.id}/master.m3u8?start=${row.timestamp * 1000}`
        }
      });
    },
    openModalViewImage(file, fullLink = false, row) {
      console.log(file, row);
      this.$rir.modal.open(ModalViewImage, {
        file,
        fullLink
      });
    },
    getSubtitle(col) {
      if (col?.subtitle) {
        return `<div class="subtitle mozzarella opacity-48 mt-1">${col.subtitle}</div>`;
      }
      return '';
    },
    async downloadVideo(id) {
      await new Api().downloadVideo(id).then(res => {
        if (res.error) {
          this.$refs.message.showMessage(res.error, 'error');
        }
      });
    },
    async orderVideo(channelId, timestamp) {
      await new Api().zakazVideo({
        action: 'order',
        camerasOption_2: 1,
        id: channelId,
        duration: 6,
        timestamp
      }).then(res => {
        if (!res.error) {
          this.$refs.message.showMessage(`Видео заказано. ${res.result}`, 'success');
        } else {
          this.$refs.message.showMessage(res.error, 'error');
        }
      });
    },
    changeStatus({ value, trackingId, plate }) {
      if (trackingId === null && !value) {
        this.putWantedList(plate);
      } else {
        this.removeWantedList(trackingId);
      }
      this.$props.reload();
    },
    async removeWantedList(trackingId) {
      await this.$store.dispatch('removeFromWantedList', trackingId).then(res => {
        if (!res.error) {
          this.$refs.message.showMessage('Номер удален из отслеживания', 'success');
        } else {
          this.$refs.message.showMessage(res.error, 'error');
        }
      });
    },
    async putWantedList(plate) {
      await this.$store.dispatch('putToWantedList', plate).then(res => {
        if (!res.error) {
          this.$refs.message.showMessage('Номер добавлен в отслеживание', 'success');
        } else {
          this.$refs.message.showMessage(res.error, 'error');
        }
      });
    },
    sortTable(col) {
      this.sorting *= -1;
      this.sortingType = col;
    }
  }
};
</script>

<style scoped lang="scss">

.dropdown {
  display: grid;
  grid-template-rows: 44px 44px;
  grid-template-columns: 1fr;

  .button {
    padding: 12px 20px !important;
    white-space: nowrap;
  }
}

.play_video_button {
  opacity: 0;
  transition: 0.2s;
}

::v-deep .subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.table {
  width: calc(100% + 32px + 40px);
  position: relative;
  left: -32px;
  overflow: hidden;

  &_image {
    background: #e4edfb;
    width: 103px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-position: center center;
      object-fit: cover;
    }
  }

  &_col {
    color: #04153e;

    &.overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_row {
    &:hover {
      .play_video_button {
        opacity: 1;
      }
    }
  }
}

.r-tooltip {
  position: relative;
}

.r-tooltip__wrapper--arrow {
  position: absolute;
  top: 100%;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  background-color: transparent !important;
}

.r-tooltip__wrapper {
  position: absolute;
  top: calc(100% + 6px);
  border-radius: 12px;
  padding: 4px 8px;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(0 4px 16px rgba(48, 3, 14, 0.16));
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
}

.table_row {
  display: grid !important;
  grid-gap: 24px;
  align-items: center;
  align-content: center;
  padding: 16px 40px 16px 32px;
  border-top: 1px solid #e4edfb;
  position: relative;
  width: max-content;
  min-width: 100%;

  &.hidden {
    overflow: hidden;
  }

  &__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f9fe;
    box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
    height: 100%;
    width: 70px;
    transform: translateX(100%);
    transition: 0.2s ease-in-out;
    transition-delay: 0.3s;
    cursor: pointer;
    opacity: 0;

    .r-icon {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      .r-icon {
        opacity: 1;
      }
    }
  }

  &:hover {
    .table_row__delete {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.table_body {
  width: max-content;
  min-width: 100%;

  .table_row {
    &:hover {
      background: #f6f9fe;
    }

    &.hidden {
      opacity: .32;
    }

    &.error {
      &:hover {
        background: #fef8f9;

        .r-tooltip__wrapper {
          opacity: 1;
        }
      }

      .r-checkbox {
        pointer-events: none;
      }

      .r-checkbox__input:before {
        border: 2px solid #e14761;
        opacity: 0.32;
      }
    }
  }
}

.table_head {
  width: max-content;
  min-width: 100%;

  .table_row {
    border: none;
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3;
  //background: red;
}

::v-deep .scrollbar-track-x {
  background: #f6f9fe;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 28px;

  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}

.r-tooltip {
  &__wrapper {
    background-color: var(--rir-amelie);
    filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
    left: -10px;
    top: calc(100% + 10px);
    max-width: 345px;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out;

    &--arrow {
      border-bottom-color: var(--rir-amelie);
      border-top-color: var(--rir-amelie);
      z-index: 100;
      top: -6px;
    }
  }
}

.pb-100px {
  padding-bottom: 100px;
}

::v-deep .c-scroll-view {
  padding-bottom: 185px;
}

.table_image {
  &.circle {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.justify-self-end {
  justify-self: end;
}
</style>
