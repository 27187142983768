<template>
  <div>
    <div class="flex align-center  mb-7">
      <router-link
        class="inline-flex align-center sulguni color-rocky mr-6"
        to="find-plate"
        transparent
      >
        <r-icon
          class="mr-2"
          icon="search"
          fill="rocky"
        />
        Найти и отслеживать номер
      </router-link>
      <r-button-action
        class="color-rocky"
        transparent
        title="Отчёт по автотрафику"
        icon="dashboard"
        @click="openChartModal"
      />
    </div>
    <div class="flex align-center">
      <r-date-picker
        label="Начало отслеживания"
        class="flex-1 mr-6"
        v-model="selectedDate"
      />
      <r-select
        class="mr-4 flex-1"
        :items="sortCarPlateType"
        label="Госномера"
        v-model="selectedCarPlateType"
      />
      <r-input
        class="flex-1"
        label="Поиск"
        v-model="search"
      >
        <template #after>
          <r-icon
            icon="search"
            fill="titanic"
            class="anie"
          />
        </template>
      </r-input>
      <r-button-simple
        size="larishae"
        class="ml-4"
        icon="icon-view"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <div class="flex justify-end parmigiano color-titanic opacity-48  mt-6 mb-2">
      {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
    </div>
    <vue-table
      :list="filteredObjects"
      :headings="headings"
      :columns-grid="tableGridColumns"
      class="rows_space-between"
      row-link="/car-plate/"
    />
  </div>
</template>

<script>
import VueTable from '../Table';
import ChartModal from '../ChartModal';
import Api from '../../api/Api';

export default {
  name: 'TabCarPlate',
  components: { VueTable },
  data() {
    return {
      selectedDate: null,
      searchText: '',
      headings: [
        {
          title: 'Госномер',
          field: 'plate'
        },
        {
          title: 'Найден на видео',
          field: 'hit'
        },
        {
          title: 'Начало отслеживания',
          field: 'date'
        },
        {
          title: 'Комментарий',
          field: 'comment'
        }
      ],
      tableGridColumns: ['103px', '150px', '170px', '140px'],
      list: [],
      sortCarPlateType: [
        {
          id: 'all',
          title: 'Все'
        },
        {
          id: 'group',
          title: 'Только группы номеров'
        },
        {
          id: 'single',
          title: 'Только номера'
        }
      ],
      selectedCarPlateType: 'all',
      selectedView: 'table'
    };
  },
  computed: {
    filteredObjects() {
      let list = this.$store.getters.getFormattedMinePlates;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => el?.columns.plate?.toLowerCase().indexOf(this.searchText) > -1
            || el?.columns?.comment?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  async created() {
    await this.$store.dispatch('getMinePlates');
  },
  methods: {
    openChartModal() {
      this.$rir.modal.open(ChartModal);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .RButtonSimple{
  width: 40px;
  height: 40px;
}
.history {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 24px;

  &__item {
    * {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      * {
        color: #214eb0;
      }
    }
  }
}
</style>
