<template>
  <div>
    <router-link
      :to="{ path: `cameras-details/${card.id}`, query: { trassir: card.isTrassir}}"
      v-if="!!!card.deletedDate"
    >
      <div class="card">
        <div class="card__image">
          <img
            :src="card.photo"
            :alt="card.description"
            v-if="!!card.photo"
          >
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path
              d="m9.42 3.25 20.83 7.58c.39.14.66.51.66.92.01.41-.24.79-.62.95l-2.48 1.01c.54.202.848.788.61 1.34l-1.5 3.5c-.2.477-.831.76-1.31.52l-3.01-1.28a3.181 3.181 0 0 1-3.95 1.46.996.996 0 0 1-.59-1.28c.2-.52.78-.78 1.29-.59.61.23 1.3-.06 1.55-.66l.27-.64.36-.88-7.69-2.96c-.52-.2-.77-.78-.58-1.29.2-.52.78-.78 1.3-.58l8.28 3.2a2244.856 2244.856 0 0 0 4.29-1.75L8.74 5.13c-.51-.19-1.05-.16-1.54.07s-.85.63-1.03 1.14L4.93 9.82c-.36 1.03.17 2.17 1.2 2.55l5.72 2.11c.01 0 .02.01.02.01l4.48 1.65a.987.987 0 0 1 .58 1.29 3.46 3.46 0 0 1-1.85 1.96c-.15.07-.31.13-.47.17l-1.94 2.89c-1.08 1.4-2.8 2.2-4.63 2.2H6v.5c0 1.66-1.35 3-3 3H2c-.55 0-1-.44-1-1v-10c0-.55.45-1 1-1h1c1.65 0 3 1.35 3 3v.77h2.04c.45 0 .87-.19 1.1-.49.34-.44.97-.52 1.41-.19.43.34.51.97.18 1.41-.62.79-1.62 1.27-2.69 1.27H6v.73h2.04c1.22 0 2.34-.51 3-1.36l1.25-1.86c-.85-.35-1.47-.99-1.78-1.8-.18-.49-.24-1.03-.17-1.58l-4.91-1.81c-2.04-.75-3.11-3.04-2.38-5.09l1.23-3.48c.36-1.01 1.1-1.82 2.07-2.28.97-.46 2.06-.51 3.07-.14ZM3 26.15c.55 0 1-.44 1-1v-6c0-.55-.45-1-1-1v8Zm10.09-8.55c.433.166.835.096 1.25-.07l-1.99-.74c.078.389.381.666.74.81Zm12.38-.76.72-1.66-.98-.42-1.6.65-.22.54 2.08.89Zm-13.54-6.41a.993.993 0 0 0-.57-1.29l-1.09-.42a.998.998 0 0 0-1.29.58c-.2.51.06 1.09.57 1.29l1.09.42c.12.04.24.06.36.06.4 0 .78-.24.93-.64Z"
              fill="#7daffc"
            />
          </svg>

          <!--<r-icon
            v-else
            icon="video-control"
            fill="alien"
            size="32"
          />-->
        </div>
        <div class="card__info">
          <p class="sulguni mb-1 overflow">
            {{ card.name || card.description }}
          </p>
          <p class="mozzarella opacity-72 mb-3 overflow">
            {{ card.address || '—' }}
          </p>
          <!-- <div class="flex align-center mb-3">
            <r-icon
              v-for="opportunity of card.opportunities"
              :key="opportunity.icon"
              class="mr-4 opacity-48"
              :icon="opportunity.icon"
              :title="opportunity.value"
              fill="titanic"
            />
          </div>
          <p
            class="mozzarella opacity-72"
            style="overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap"
          >
            {{ card.groups.join(', ') }}
          </p>-->
        </div>
        <!--<div
          class="flex align-center"
          v-if="card.handmade"
        >
          <additional-menu>
            <ul class="card__menu">
              <li
                class="sulguni flex pointer opacity-72 mb-6"
                @click.stop.prevent="copyItem"
              >
                <r-icon
                  class="mr-3"
                  icon="copy"
                  fill="rocky "
                />
                Дублировать
              </li>
              <li
                class="sulguni flex pointer opacity-72"
                @click.stop.prevent="onDelete"
              >
                <r-icon
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                />
                Удалить
              </li>
            </ul>
          </additional-menu>
        </div> -->
      </div>
    </router-link>
    <div
      class="card deleted_card"
      v-else
    >
      <div class="card__image">
        <img
          :src="card.image"
          :alt="card.title"
          v-if="!!card.image"
        >
        <r-icon
          v-else
          icon="video-control"
          fill="fargo"
          size="32"
        />
      </div>
      <div class="card__info">
        <p class="sulguni mb-1">
          {{ card.title }}
        </p>
        <p class="mozzarella opacity-72 mb-3">
          {{ card.address }}
        </p>
        <div class="flex align-center mb-3">
          <r-icon
            v-for="(icon, index) of card.opportunities"
            :key="`icon${card.id}${index}`"
            class="mr-4 opacity-48"
            :icon="icon"
            fill="titanic"
          />
        </div>
        <div
          class="flex align-center"
          style="z-index: 2"
        >
          <r-icon
            class="mr-2"
            icon="block"
            fill="fargo"
          />
          <p class="briscola color-fargo">
            Будет удалено {{ card.deletedDate }}
          </p>
        </div>
      </div>
      <div
        class="flex align-center"
        style="z-index: 2"
      >
        <r-icon
          class="pointer"
          icon="restore"
          @click.native="onRestore"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalMenu from '../AdditionalMenu.vue';
import Api from '../../api/Api';
import DeleteModal from '../DeleteModal';

export default {
  name: 'ControllerCard',
  components: { AdditionalMenu },
  props: {
    card: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowPopover: false
    };
  },

  computed: {},
  methods: {
    async onDelete() {
      await this.$rir.modal.open(DeleteModal, {
        id: this.$props.id,
        actionPostfix: 'Excavation'
      });
    },
    async onRestore() {
      const api = new Api();
      await api.restoreExcavation(this.id);
    },
    copyItem() {
      this.$router.push({ path: `controller-edit/${this.card.id}`, query: { copy: true } });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: grid;
  grid-template-columns: 72px auto 16px;
  grid-gap: 12px;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px;
  border-radius: 16px;
  overflow: hidden;

  &.deleted_card {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  &__image {
    background: #e4edfb;
    border-radius: 8px;
    overflow: hidden;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }

  &__header {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 6px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.disabled {
      .sulguni,
      .mozzarella {
        opacity: 0.32 !important;
      }
    }
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.popover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.popover__content {
  overflow: visible !important;
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
}
.overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
