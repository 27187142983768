<template>
  <div>
    <router-link
      class="inline-flex align-center sulguni color-rocky"
      to="person-add"
      v-if="this.$store.getters.userIsRoot"
    >
      <r-icon
        class="mr-2"
        icon="add"
        fill="rocky"
      />
      Добавить персону
    </router-link>
    <r-tabs
      class="mt-7 mb-7"
      :items="tabs"
      v-model="activeTabId"
    />
    <sub-tab-persons v-if="activeTabId.id === 'persons'" />
    <sub-tab-monitored v-if="activeTabId.id === 'monitored'" />
    <!--<template>
      <p class="mozzarella mb-6 mt-6 opacity-48">
        История запросов
      </p>
      <div class="history">
        <router-link
          to="#"
          class="history__item"
          v-for="item of 5"
          :key="item"
        >
          <span class="sulguni opacity-72 block">Кудров Валерий Михайлович{{ item }}</span>
          <span class="briscola opacity-48 block">28.11.2020 15:30:02</span>
        </router-link>
      </div>
    </template>-->
  </div>
</template>

<script>
import SubTabPersons from './SubTabPersons';
import SubTabMonitored from './SubTabMonitored';

export default {
    name: 'TabPersons',
  components: { SubTabPersons, SubTabMonitored },
  data() {
    return {
      selectedDate: null,
      searchText: '',
      activeTabId: {
        id: 'persons',
        title: 'Персоны'
      },
      tabs: [
        {
          id: 'persons',
          title: 'Персоны'
        },
        {
          id: 'monitored',
          title: 'Мои отслеживания'
        }
      ]
    };
  },
  async activated() {
    this.activeTabId = this.tabs.find(item => item.id === this.$route.query.subtab)?.id || 'persons';
  }
};
</script>

<style scoped lang="scss">
.history {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 24px;

  &__item {
    * {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      * {
        color: #214eb0;
      }
    }
  }
}

</style>
