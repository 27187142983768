<template>
  <div>
    <router-link
      :to="{ path: `cameras-details/${card.id}`, query: { trassir: card.isTrassir } }"
      v-if="!!!card.deletedDate"
    >
      <div class="card">
        <div class="card__image">
          <img
            :src="`/trassir/rest/channel/${card.id}/thumb`"
            :alt="card.name"
          >
          <r-icon
            v-if="errorImage"
            icon="camera-cctv"
            size="32"
            fill="rocky"
          />
        </div>
        <div class="card__info">
          <p class="sulguni mb-1 overflow">
            {{ card.name || card.description }}
          </p>
          <p class="mozzarella opacity-72 mb-3 overflow">
            {{ card.address || '—' }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import axios from 'axios';
// import AdditionalMenu from '../AdditionalMenu.vue';
import Api from '../../api/Api';
// import DeleteModal from '../DeleteModal';

export default {
  name: 'CamCard',
  components: { },
  props: {
    card: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowPopover: false,
      errorImage: true,
      imageUrl: true
    };
  },

  computed: {},
  async mounted() {
    const observer = new IntersectionObserver(async entries => {
      if (entries[0].isIntersecting) {
        await this.getUrlImage();
      }
    });
    observer.observe(this.$el);
  },

  methods: {
    async getUrlImage() {
      // await new Api().getFromUrl(`//${this.$props.card.id}/thumb`).then(res => {
      //   console.log(res.config.url);
      // });
      if (this.$props.card.photo) {
        this.imageUrl = this.$props.card.photo;
      } else {
        this.imageUrl = `/trassir/rest/channel/${this.$props.card.id}/thumb`;
        // await new Api().getUrlImage(this.$props.card.id).then(response => {
        //   const blob = new Blob([response.data]);
        //   this.imageUrl = URL.createObjectURL(blob);
        //   this.errorImage = false;
        // });
      }
    },

    async onDelete() {
      await this.$rir.modal.open(DeleteModal, {
        id: this.$props.id,
        actionPostfix: 'Excavation'
      });
    },
    async onRestore() {
      const api = new Api();
      await api.restoreExcavation(this.id);
    },
    copyItem() {
      this.$router.push({ path: `controller-edit/${this.card.id}`, query: { copy: true } });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: grid;
  grid-template-columns: 72px auto 16px;
  grid-gap: 12px;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px;
  border-radius: 16px;
  overflow: hidden;

  &.deleted_card {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  &__image {
    background: #e4edfb;
    border-radius: 8px;
    overflow: hidden;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }

  &__header {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 6px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.disabled {
      .sulguni,
      .mozzarella {
        opacity: 0.32 !important;
      }
    }
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.popover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.popover__content {
  overflow: visible !important;
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
}
.overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
